import { isAxiosError } from "axios";
import {
  CreateWalletForm,
  Payment,
  PaymentInitData,
  VerifyOrder,
  Wallet,
  WalletTransaction,
  API_PATH,
} from "shared";
import { AxiosApi } from ".";

export const getWalletApi = async () => {
  try {
    const _data = await AxiosApi.get<Wallet | null>(
      API_PATH.wallet.walletByClient.frontend
    );
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const addFundToWalletApi = async (amount: number) => {
  try {
    const _data = await AxiosApi.post<PaymentInitData>(API_PATH.wallet.addFund.frontend, {
      amount,
    });
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const verifyWalletPaymentApi = async (form: VerifyOrder) => {
  try {
    const _data = await AxiosApi.post<Payment>(`/wallet/verify_payment`, form);
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const createWalletApi = async (form: CreateWalletForm) => {
  try {
    const _data = await AxiosApi.post(API_PATH.wallet.create.frontend, form);
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const getWalletDetailsApi = async () => {
  try {
    const _data = await AxiosApi.get<Wallet>(API_PATH.wallet.details.frontend);
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const getWalletTransactionsApi = async () => {
  try {
    const _data = await AxiosApi.get<WalletTransaction[]>(
      API_PATH.wallet.transactions.frontend
    );
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};
