import { Button, Divider } from "@mui/material";
import React, { FC, useState } from "react";
import { InputField } from "ui/inputs";
import { Body, ErrorMessage } from "ui/typography";
import ConsentPage from "./WalletConsent";
import { CreateWalletForm } from "shared";

const WalletForm: FC<{
	submit: () => void;
	form: CreateWalletForm;
	setForm: (form: CreateWalletForm) => void;
	error: string;
}> = ({ form, setForm, submit, error }) => {
	const [showConsent, setShowConsent] = useState(false);

	const handleSubmit = (e: any) => {
		e.preventDefault();
		submit();
	};
	return (
		<div
			style={{
				maxWidth: "100%",
				padding: "15px",
				margin: "auto",
			}}
		>
			<div>
				<form onSubmit={handleSubmit}>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<Body text={"Create wallet"} />
						<Divider style={{ maxWidth: "40%" }} />
					</div>
					<div style={{ display: "flex", padding: "auto" }}>
						<InputField
							value={form.firstname}
							label="Firstname "
							placeholder="John"
							type="text"
							required={true}
							onChange={(value) => setForm({ ...form, firstname: value })}
						/>
					</div>
					<div style={{ display: "flex", padding: "auto" }}>
						<InputField
							value={form.lastname}
							label="Surname"
							placeholder="Doe"
							type="text"
							required={true}
							onChange={(value) => setForm({ ...form, lastname: value })}
						/>
					</div>
					<div style={{}}>
						<span
							style={{ fontSize: "14px" }}
							className={"poppins-extralight-italic"}
						>
							{`I consent to the use of my name for creating a wallet and a
							dedicated virtual account`}
						</span>
						<span
							className="poppins-extralight-italic"
							style={{
								color: "blue",
								cursor: "pointer",
								fontSize: "14px",
								paddingLeft: "5px",
							}}
							onClick={() => setShowConsent(true)}
						>
							read detail
						</span>
					</div>
					<div style={{ textAlign: "right" }}>
						<label htmlFor="consentGiven">
							<input
								type="checkbox"
								checked={form.consentGiven}
								onChange={(e) =>
									setForm({ ...form, consentGiven: e.target.checked })
								}
								required
							/>
							<span className="poppins-extralight-italic">Consent</span>
						</label>
					</div>
					<ConsentPage
						show={showConsent}
						close={() => setShowConsent(false)}
						onConsent={() => setForm({ ...form, consentGiven: true })}
					/>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						disabled={!form.consentGiven}
					>
						{" "}
						Continue
					</Button>

					<div
						style={{
							display: "flex",
							// justifyContent: "end",
							marginTop: "15px",
							gap: "5px",
						}}
					></div>

					{error && <ErrorMessage text={error} />}
				</form>
			</div>
		</div>
	);
};

export default WalletForm;
