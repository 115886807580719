import { isAxiosError, AxiosInstance } from "axios";
import {
  CreateWalletForm,
  Payment,
  PaymentInitData,
  VerifyOrder,
  Wallet,
  WalletTransaction,
} from "../interface";
import { API_PATH } from "../apiPath";

export const getWalletApi = async (axiosInstance: AxiosInstance) => {
  try {
    const _data = await axiosInstance.get<Wallet | null>(
      API_PATH.wallet.walletByClient.frontend
    );
    return _data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
 };

export const addFundToWalletApi = async (axiosInstance: AxiosInstance, amount: number) => {
  try {
    const _data = await axiosInstance.post<PaymentInitData>(
      API_PATH.wallet.addFund.frontend,
      {
        amount,
      }
    );
    return _data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
 };

export const verifyWalletPaymentApi = async (axiosInstance: AxiosInstance, form: VerifyOrder) => {
  try {
    const _data = await axiosInstance.post<Payment>(`/wallet/verify_payment`, form);
    return _data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
 };

export const createWalletApi = async (axiosInstance: AxiosInstance, form: CreateWalletForm) => {
  try {
    const _data = await axiosInstance.post(API_PATH.wallet.create.frontend, form);
    return _data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
 };

export const getWalletDetailsApi = async (axiosInstance: AxiosInstance) => {
  try {
    const _data = await axiosInstance.get<Wallet>(API_PATH.wallet.details.frontend);
    return _data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
 };

export const getWalletTransactionsApi = async (axiosInstance: AxiosInstance) => {
  try {
    const _data = await axiosInstance.get<WalletTransaction[]>(
      API_PATH.wallet.transactions.frontend
    );
    return _data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
 };