

const staff = "/staff";

type ApiPath = {
  frontend: string;
  backend: string;
};

export const StaffPath = {
  staff,
};

const api_prefix = "api";

const handlePath = ({ path }: { path: string }) => {
  const apiPath: ApiPath = {
    frontend: `${api_prefix}${staff}/${path}`,
    backend: `/${path}`,
  };
  return apiPath;
};

export const Staff_API_PATH = {
  create: handlePath({ path: "create" }),
  getActiveStaffs: handlePath({ path: "active" }),
};
