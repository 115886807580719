import React, { useState, useEffect } from "react";
import {
	Box,
	Button,
	Typography,
	List,
	ListItem,
	Divider,
	Card,
	CardContent,
	Avatar,
	Grid,
	Grid2,
} from "@mui/material";
import { useClientStore } from "store";
import { AxiosApi } from "api";
import { startShiftApi, endShiftApi, getTodayShiftApi } from "shared";
import Loading from "ui/isLoading";
import { useNavigate } from "react-router-dom";
import { useShiftStore } from "store/shift";
import { BodyHeader, BodyWithClass } from "ui/typography";
import { format } from "date-fns";
import { Btn } from "ui/button";

const StaffShiftPage = () => {
	const [staffWorking, setStaffWorking] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const { staff } = useClientStore(); // Assuming `staff` contains staff details.
	const { shift, setShift, clearShift } = useShiftStore();
	const navigate = useNavigate();

	useEffect(() => {
		console.log("StaffShiftPage");
		fetchShiftDetails();
	}, []);

	const fetchShiftDetails = async () => {
		setLoading(true);
		if (!staff) {
			setError("Staff not found");
			setLoading(false);
			return;
		}

		try {
			const response = await getTodayShiftApi(AxiosApi);
			if (response) {
				setShift(response);
				setStaffWorking(response.workingNow);
			}
		} catch (err) {
			setError("Unable to fetch shift details. Please try again.");
		} finally {
			setLoading(false);
		}
	};

	const handleStartShift = async () => {
		setLoading(true);
		try {
			await startShiftApi(AxiosApi);
			fetchShiftDetails();
		} catch (err) {
			setError("Failed to start shift. Please try again.");
		} finally {
			setLoading(false);
		}
	};

	const handleEndShift = async () => {
		setLoading(true);
		try {
			await endShiftApi(AxiosApi);
			clearShift();
			setStaffWorking(false);
			navigate("/dashboard");
		} catch (err) {
			setError("Failed to end shift. Please try again.");
		} finally {
			setLoading(false);
		}
	};

	if (loading) return <Loading />;

	return (
		<Box sx={{ p: 3 }}>
			{error && (
				<Typography color="error" sx={{ mt: 2 }}>
					{error}
				</Typography>
			)}
			<Box sx={{ mb: 2 }}>
				<Typography>{format(new Date(), "eee dd MM yyyy h:mm")}</Typography>
			</Box>
			<div>
				<BodyHeader text={`Welcome ${staff?.firstname} `} />
			</div>
			<div>
				<BodyWithClass text={`Are you working today ? `} className={""} />
			</div>
			<div>
				{staffWorking ? (
					<Box sx={{ mt: 2 }}>
						<Typography>
							Start Time:{" "}
							{new Date(shift?.startTime as any).toLocaleTimeString()}
						</Typography>
						<Typography sx={{ mb: 2 }}>
							{/* Duration: {shift?.duration || "0 hours 0 minutes"} */}
						</Typography>
						<Button variant="contained" color="error" onClick={handleEndShift}>
							End Shift
						</Button>
					</Box>
				) : (
					<div style={{ marginTop: "20px" }}>
						<Btn text={"Start shift"} onClick={handleStartShift} />
					</div>
				)}
			</div>
		</Box>
	);
};

export default StaffShiftPage;
