import { AxiosApi } from "api";
import React, { useEffect, useState } from "react";
import { Order, OrderApiFilterParam, getFilteredOrdersApi } from "shared";
import DashboardOrderList from "ui/dashboard/OrderList";
import OrderFilterDrawer from "ui/order/FilterDrawer";

const DashboardOrderHistory = () => {
	const [filter, setFilter] = useState<OrderApiFilterParam>({
		...Object(),
		orderStatus: "",
		staffId: "",
		paymentStatus: "",
	});
	const [loading, setLoading] = useState(true);
	const [orders, setOrders] = useState<Order[]>([]);

	useEffect(() => {
		getFilteredOrdersApi(AxiosApi, filter)
			.then((response) => {
				setOrders(response);
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [filter]);

	return (
		<div
			style={{
				padding: 20,
				width: "100%",
				margin: "auto",
				height: "100%",
			}}
		>
			<div style={{ backgroundColor: "transparent" }}>
				<OrderFilterDrawer filter={filter} setFilter={setFilter} />
			</div>
			<div style={{ overflow: "auto", maxHeight: "100%", height: "900px" }}>
				<DashboardOrderList orders={orders} loading={loading} />
			</div>
		</div>
	);
};

export default DashboardOrderHistory;
