import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { createTheme } from "@mui/material/styles";
import CloudCircleIcon from "@mui/icons-material/CloudCircle";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SearchIcon from "@mui/icons-material/Search";
import {
	AppProvider,
	Router,
	type Navigation,
} from "@toolpad/core/AppProvider";
import {
	DashboardLayout,
	ThemeSwitcher,
	type SidebarFooterProps,
} from "@toolpad/core/DashboardLayout";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useClientStore } from "store";
import { useEffect, useState } from "react";
import { DASHBOARD_NAVIGATION } from "utils/dashboard/navigation";
import SnackbarWithCloseButton from "ui/SnackBar";

const handleNavigationItem = () => {};
const demoTheme = createTheme({
	cssVariables: {
		colorSchemeSelector: "data-toolpad-color-scheme",
	},
	colorSchemes: { light: true, dark: true },
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 600,
			lg: 1200,
			xl: 1536,
		},
	},
});

function ToolbarActionsSearch() {
	return (
		<Stack direction="row">
			<Tooltip title="Search" enterDelay={1000}>
				<div>
					<IconButton
						type="button"
						aria-label="search"
						sx={{
							display: { xs: "inline", md: "none" },
						}}
					>
						<SearchIcon />
					</IconButton>
				</div>
			</Tooltip>
			<TextField
				label="Search"
				variant="outlined"
				size="small"
				slotProps={{
					input: {
						endAdornment: (
							<IconButton type="button" aria-label="search" size="small">
								<SearchIcon />
							</IconButton>
						),
						sx: { pr: 0.5 },
					},
				}}
				sx={{ display: { xs: "none", md: "inline-block" }, mr: 1 }}
			/>
			<ThemeSwitcher />
		</Stack>
	);
}

function SidebarFooter({ mini }: SidebarFooterProps) {
	return (
		<Typography
			variant="caption"
			sx={{ m: 1, whiteSpace: "nowrap", overflow: "hidden" }}
		>
			{mini ? "© MUI" : `© ${new Date().getFullYear()} Made with love by MUI`}
		</Typography>
	);
}

function CustomAppTitle() {
	return (
		<Stack direction="row" alignItems="center" spacing={2}>
			<CloudCircleIcon fontSize="large" color="primary" />
			<Typography variant="h6">Urbanline Gas</Typography>
		</Stack>
	);
}

export default function DashboardLayoutPage() {
	const { staff } = useClientStore();
	const navigate = useNavigate();
	const location = useLocation();
	const [dashboardItem, setDashboardItem] = useState(DASHBOARD_NAVIGATION.main);

	const isOrderDetailPath = () => {
		const match = location.pathname.match(/dashboard\/order\/\d+/);
		return match !== null;
	};

	useEffect(() => {}, [staff]);

	useEffect(() => {
		// handle given order
		console.log(isOrderDetailPath());
		// setDashboardItem(nav => ({nav}))
	}, [location.pathname]);

	// function createReactRouterAdapter() {

	// 	return {
	// 		pathname: location.pathname,
	// 		push: (path:any)  => navigate(path),
	// 		replace: (path:any) => navigate(path, { replace: true }),
	// 	};
	// }

	const createReactRouterAdapter = (): Router => {
		const router: Router = {
			pathname: location.pathname,
			searchParams: new URLSearchParams(location.search),
			navigate: (url, options) => {
				console.log("navigate", url, options);
				const method = options?.history === "replace" ? "replace" : "push";
				navigate(url, { replace: method === "replace" });
			},
		};

		return router;
	};
	return (
		<AppProvider
			navigation={dashboardItem}
			router={createReactRouterAdapter()}
			theme={demoTheme}
			className="dashboard-container"
		>
			<DashboardLayout
				sidebarExpandedWidth={250}
				slots={{
					appTitle: CustomAppTitle,
					toolbarActions: ToolbarActionsSearch,
					sidebarFooter: SidebarFooter,
				}}
				sx={{
					"& .MuiDrawer-paper": {
						borderRight: "none",
					},

					"& .Mui-selected": {
						backgroundColor: "#1976d2 !important", // Active background color
						color: "#fff", // Active text color
					},
				}}
			>
				<div
					style={{
						width: "97%",
						height: "95%",
						backgroundColor: "#f5d7d0",
						borderRadius: "20px",
					}}
				>
					<SnackbarWithCloseButton />
					<Outlet />
				</div>
			</DashboardLayout>
		</AppProvider>
	);
}
