import React from "react";
import { Button, Box, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

const AccessDenied = () => {
	const navigate = useNavigate();
	const location = useLocation();

	// Determine the redirect path based on the URL
	const redirectPath = location.pathname.includes("dashboard")
		? "/dashboard"
		: "/";

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				height: "100vh",
				textAlign: "center",
				padding: 2,
			}}
		>
			<Typography variant="h4" gutterBottom>
				Access Denied
			</Typography>
			<Typography variant="body1" sx={{ marginBottom: 2 }}>
				You do not have permission to view this page.
			</Typography>
			<Button
				variant="contained"
				color="primary"
				onClick={() => navigate(redirectPath)}
			>
				{redirectPath === "/dashboard" ? "Back to Dashboard" : "Back to Home"}
			</Button>
		</Box>
	);
};

export default AccessDenied;
