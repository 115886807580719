import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { PaymentPurpose, PaymentStatus, PaymentUsableData } from "shared";
import { usePaymentStore } from "store/payment";
import PaystackPop from "@paystack/inline-js";
import Info from "ui/Info";
import { Btn, ButtonProps } from "ui/button";
import { COLORS } from "utils/constants";
import { BodyWithClass } from "ui/typography";
import BankCardDetails from "ui/payment/BankCard";
import {
  discardPaymentUsableRedisDataApi,
  getPaymentUsableDataApi,
  savePaymentUsableDataApi,
} from "api/paymnet";

enum ActionType {
  Close = "Close",
  Success = "Success",
  Error = "Error",
}
interface PopAction {
  title: string;
  message: string;
  type: ActionType;
  actions: ButtonProps[];
}
const PaymentPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [retryPayment, setRetryPayment] = useState(false);
  const [popAction, setPopAction] = useState<PopAction | null>(null);
  const [bankCardData, setBankCardData] = useState<PaymentUsableData | null>(
    null
  );

  const { accessCode, id: orderNumber, purpose } = useParams();

  const { authorizationUrl, ref, paymentNumber } = usePaymentStore();
  const handleOrderPaymentSuccess = () => {};
  useEffect(() => {
    //Todo: check the payment reference if the payment has been made
    if (accessCode) {
      const paystackPopup = new PaystackPop();

      const te = paystackPopup.resumeTransaction(accessCode, {
        onCancel: (data: any) => {
          setPopAction({
            title: "Canceling your payment?",
            actions: [
              {
                style: { backgroundColor: "red", color: "white" },
                onClick: () => {
                  const path =
                    purpose === PaymentPurpose.FundWallet
                      ? `/wallet`
                      : `/order/${orderNumber}`;
                  navigate(path);
                },
                text: "Close payment",
              },
              {
                style: { backgroundColor: COLORS.Blue, color: "white" },
                onClick: () => setRetryPayment(!retryPayment),
                text: "Continue  to Pay",
              },
            ],
            type: ActionType.Close,
            message: "Are you sure you want to cancel your  payment ? ",
          });
        },
        onSuccess: (transaction: {
          id: number;
          reference: string;
          message: string;
          status: PaymentStatus;
        }) => {
          // setPopAction({
          // 	action: () => {},
          // 	actionType: ActionType.Success,
          // 	messages: "",
          // });

          setTimeout(() => {
            const path =
              purpose === PaymentPurpose.FundWallet
                ? `${paymentNumber}/${purpose}`
                : `${orderNumber}/${paymentNumber}/${purpose}`;
            navigate(`/payment/success/${path}`);
            // getPaymentUsableDataApi()
            // 	.then((data) => {
            // 		setBankCardData(data);
            // 	})
            // 	.catch((err) => {
            // 		console.log(console.log(err));
            // 	})
            // 	.finally(() => {
            // 		setLoadingCard(false);
            // 	});
          }, 2000);
          setPaymentSuccess(true);
        },
        onLoad: (e) => {
          // console.log(e);
        },
        onError: () => {
          //
          setPopAction({
            title: "Payment error",
            actions: [
              {
                style: { backgroundColor: "red", color: "white" },
                onClick: () => {
                  navigate(`/order/${orderNumber}`);
                },
                text: "Close payment",
              },
              {
                style: { backgroundColor: COLORS.Blue, color: "white" },
                onClick: () => {
                  console.log("on clicked");
                },
                text: "Try again",
              },
            ],
            type: ActionType.Close,
            message: "Something went wrong with the payment page !",
          });
        },
      });
    }
  }, [accessCode, retryPayment]);

  useEffect(() => {
    if (popAction) {
      // Hide the Paystack popup when showing the Info modal
      const iframe = document.querySelector("iframe");
      if (iframe) {
        iframe.classList.add("paystack-hidden");
      }
    }
  }, [popAction]);

  const handleSaveBankCard = () => {
    savePaymentUsableDataApi()
      .then((response) => {})
      .catch((err) => {})
      .finally(() => {
        handleNavigate();
      });
  };

  const handleDiscardBankCard = () => {
    discardPaymentUsableRedisDataApi()
      .then((response) => {})
      .catch((err) => {})
      .finally(() => {
        handleNavigate();
      });
  };

  const handleNavigate = () => {
    if (purpose === PaymentPurpose.FundWallet) {
      navigate("/wallet");
    } else if (purpose === PaymentPurpose.OrderPayment) {
      navigate(`/order/${orderNumber}`);
    } else {
      navigate("/");
    }
  };

  return (
    <div style={{ height: "100vh", backgroundColor: "white" }}>
      {paymentSuccess ? (
        <div>
          <div>
            {purpose === PaymentPurpose.FundVirtualAccount ? (
              <BodyWithClass
                text={
                  "Payment success, you will be redirect to the order page in "
                }
                className={"poppins-semibold"}
                style={{ fontSize: "22px", color: COLORS.Blue }}
              />
            ) : (
              <BodyWithClass
                text={
                  "Payment success, you will be redirect to the order page in 1  minute  or click blow to go to order page"
                }
                className={"poppins-semibold"}
                style={{ fontSize: "22px", color: COLORS.Blue }}
              />
            )}
          </div>
          <Btn
            text={"View wallet"}
            onClick={() => navigate(`/order/${orderNumber}`)}
          />
          <div>
            {bankCardData && (
              <div>
                <BankCardDetails
                  {...bankCardData}
                  saveData={handleSaveBankCard}
                  discardData={handleDiscardBankCard}
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <Info
          title={popAction?.title as any}
          show={popAction !== null}
          setShow={() => setPopAction(null)}
          description={popAction?.message as any}
          actions={popAction?.actions || []}
        />
      )}
    </div>
  );
};

export default PaymentPage;
