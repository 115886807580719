import { Button, IconButton } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { SvgIconComponent } from "@mui/icons-material";
import { BodyWithClass } from "ui/typography";

type ButtonType =
	| "primary"
	| "secondary"
	| "success"
	| "danger"
	| "intermediate";

export interface ButtonProps {
	key?: any;
	type?: ButtonType;
	onClick?: () => void;
	children?: React.ReactNode;
	isLoading?: boolean;
	isDisabled?: boolean;
	text: string;
	icon?: any;
	style?: React.CSSProperties;
}

export const Btn: React.FC<ButtonProps> = ({
	type,
	onClick,
	isLoading,
	isDisabled,
	text,
	style,
	key,
}) => {
	const handleClick = () => {
		if (onClick && !isLoading && !isDisabled) {
			onClick();
		}
	};
	return (
		<Button
			style={{
				textTransform: "none",
				minWidth: "100px",
				...style,
				backgroundColor: isDisabled ? "grey" : style?.backgroundColor,
			}}
			size="small"
			disableRipple
			variant="contained"
			className="color"
			//className={`button ${type}`}

			onClick={handleClick}
			disabled={isLoading || isDisabled}
		>
			{isLoading ? "Loading..." : text}
		</Button>
	);
};

export const BtnWithIcon: React.FC<
	ButtonProps & { iconPath: string; iconName: string }
> = ({ type, onClick, isLoading, isDisabled, text, iconPath, iconName }) => {
	const handleClick = () => {
		if (onClick && !isLoading && !isDisabled) {
			onClick();
		}
	};

	return (
		<Button
			variant="contained"
			className={`button ${type} color`}
			onClick={handleClick}
			disabled={isLoading || isDisabled}
			endIcon
			fullWidth
		>
			<div style={{ width: "100%", display: "flex", alignItems: "center" }}>
				<span style={{ display: "flex" }}>
					<img src={iconPath} alt={iconName} style={{ marginRight: "8px" }} />
				</span>
				<span style={{ textAlign: "center", width: "100%" }}>
					{isLoading ? "Loading..." : text}
				</span>
			</div>
		</Button>
	);
};

export const BtnIcon: React.FC<{
	Icon: SvgIconComponent;
	iconColor: any;
	isDisabled?: boolean;
	onClick?: () => void;
	style?: React.CSSProperties;
	name: string;
	backgroundColor?: string;
}> = ({
	onClick,
	Icon,
	isDisabled,
	style,
	iconColor = "",
	name,
	backgroundColor,
}) => {
	const handleClick = () => {
		if (onClick && !isDisabled) {
			onClick();
		}
	};

	return (
		<div style={{}}>
			<IconButton
				aria-label={name}
				style={style}
				onClick={handleClick}
				disableTouchRipple
				disabled={isDisabled}
				sx={{ color: iconColor }}
			>
				<Icon color={iconColor} />
			</IconButton>
			<BodyWithClass
				style={{ color: iconColor }}
				text={name}
				className={"poppins-light-italic"}
			/>
		</div>
	);
};

export const BtnWithNavigation: React.FC<{ text: string; path: string }> = ({
	text,
	path,
}) => {
	const navigate = useNavigate();

	const handleClick = () => {
		navigate(`/${path}`);
	};

	return (
		<Button
			variant="outlined"
			onClick={handleClick}
			color="success"
			className="color"
		>
			{text}
		</Button>
	);
};
