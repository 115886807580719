export const SOCKET_EVENTS = {
  pickup: {
    requestConfirmationStaff: "requestConfirmationStaff",
    requestPickupConfirmationToClient: "requestPickupConfirmation",
    pingClient: "pingClient",
    pingClientNoResponding: "pingClientNoResponse",
    pingStaff: "pingStaff",
    staffBusy: "staffBusy",
    staffConfirm: "staffConfirm", 
    clientOffLine: "clientOffLine",
    pingClientResponse: "pingClientResponse",
    clientPickupConfirmationResponse: "pickupConfirmationResponse",
    clientPickupConfirmationResponseStaff: "pickupConfirmationResponseStaff",
    //   clientPickupApprovedStaff: "clientPickupApprovedStaff",
    //   clientPickupRejectedStaff: "clientPickupRejectedStaff",
    requestConfirmationError: "requestConfirmationError",
    orderNotFound: "orderNotFound",
    pickupError: "pickupError",
  },
};

export const SOCKET_PICKUP_EVENTS_STAFF = {
  clientPickupConfirmationResponse: "clientPickupConfirmationResponse",
  clientPickupConfirmationResponseStaff:
    "clientPickupConfirmationResponseStaff",
  requestConfirmationError: "requestConfirmationError",
  orderNotFound: "orderNotFound",
  pickupError: "pickupError",
  ClientDelayResponse: "ClientDelayResponse",
  ClientBackOnline: "ClientBackOnline",
  ClientRequestCancelled: "ClientRequestCancelled",
  ClientOffLine: "ClientOffLine",
  CancelWarning: "CancelWarning",
  StaffCancelRequest: "StaffCancelRequest",
  StaffApproveOwnOrder : "staffApproveOwnOrder",
  StaffCheckError : "StaffCheckError",
  staffConfirmApproval : "StaffConfirmApproval",
  duplicatedRequest: "duplicatedRequest",
  ClientExperiencingError : "ClientExperiencingError",
  OrderAlreadyPickup : "OrderAlreadyPickup",
  pickupEligibilityStatus : "PickupEligibilityStatus"

};
