import { StaffAccess } from "../interface";

export const StaffAccessKeys = Object.keys(StaffAccess).map((key) => ({
  key,
  value: StaffAccess[key as keyof typeof StaffAccess],
}));

export function staffHasAnyAccess({
  currentAccess,
  expectedAccess,
}: {
  currentAccess: StaffAccess[];
  expectedAccess: StaffAccess[];
}): boolean {
  const alwaysAllowedAccess = [StaffAccess.CEO, StaffAccess.Manager];

  if (currentAccess.some((access) => alwaysAllowedAccess.includes(access))) {
    return true;
  }

  if (expectedAccess.includes(StaffAccess.All)) {
    return true;
  }

  return expectedAccess.some((access) => currentAccess.includes(access));
}

export function staffHasAllAccess({
  currentAccess,
  onlyAccess,
}: {
  currentAccess: StaffAccess[];
  onlyAccess: StaffAccess[];
}): boolean {
  return onlyAccess.every((access) => currentAccess.includes(access));
}

export function staffHasOnlyStaffAccess(currentAccess: StaffAccess[]): boolean {
  if (currentAccess.length === 1) {
    if (currentAccess[0] === StaffAccess.Staff) return true;
  }

  return false;
}
