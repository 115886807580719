//import { api_prefix } from "./index";

const order = "/order";

type ApiPath = {
  frontend: string;
  backend: string;
};

export const OrderPath = {
  order,
};

const api_prefix = "api";

const handlePath = ({ path }: { path: string }) => {
  const apiPath: ApiPath = {
    frontend: `${api_prefix}${order}/${path}`,
    backend: `/${path}`,
  };
  return apiPath;
};

export const Order_API_PATH = {
  newOrder: handlePath({
    path: `new`,
  }),
  getOrderByNumber: (orderNumber: string) =>
    handlePath({
      path: orderNumber,
    }),
  getOrdersByClient: handlePath({
    path: `client`,
  }),
  cancelOrder: (orderId: string) =>
    handlePath({
      path: `cancel/${orderId}`,
    }),

  confirmPayment: (orderId: string) =>
    handlePath({
      path: `confirm_payment/${orderId}`,
    }),

  getQrCode: (code: string) =>
    handlePath({
      path: `qr_code/${code}`,
    }),

  getOrderByPaymentNumber: (paymentNumber: string) =>
    handlePath({
      path: `payment_number/${paymentNumber}`,
    }),

  verifyOrderPayment: (orderNumber: any) =>
    handlePath({
      path: `payment_verify/${orderNumber}`,
    }),

  filterOrders: handlePath({
    path: `filtered`,
  }),
  orderWithClient: (orderNumber: any) =>
    handlePath({
      path: `with_client/${orderNumber}`,
    }),
  searchOrderWithClient: handlePath({
    path: `with_client/search`,
  }),

  pickupOrder:  (pickupCode: string) => handlePath({
    path: `pickup/`+pickupCode
  }),
};
