import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
	Collapse,
	Dialog,
	List,
	ListItemButton,
	ListItemText,
	ListSubheader,
} from "@mui/material";
import React, { useState } from "react";
import { Btn } from "ui/button";

interface ConsentPageProps {
	onConsent: () => void;
	show: boolean;
	close: () => void;
}

const ConsentPage: React.FC<ConsentPageProps> = ({
	onConsent,
	show,
	close,
}) => {
	const [showDetail, setShowDetail] = useState({ name: false, consent: false });

	const handleConsent = () => {
		close();
		onConsent();
	};
	return (
		<Dialog open={show} onClose={close}>
			<List
				sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
				component="nav"
				aria-labelledby="nested-list-subheader"
				style={{ padding: "15px" }}
				subheader={
					<ListSubheader component="div" id="nested-list-subheader">
						Consent to create a wallet
					</ListSubheader>
				}
			>
				<List component="div" disablePadding>
					I consent to the use of my name for creating a wallet and a dedicated
					virtual account.
				</List>
				<ListItemButton
					onClick={() =>
						setShowDetail({ ...showDetail, name: !showDetail.name })
					}
				>
					<ListItemText primary="Why do we need this?" />

					{showDetail.name ? <ExpandLess /> : <ExpandMore />}
				</ListItemButton>
				<Collapse in={showDetail.name} timeout="auto" unmountOnExit>
					<List component="div" disablePadding>
						We need your name to create a personalized wallet and a dedicated
						virtual account for you. This ensures that your financial
						transactions are secure and uniquely tied to your identity.
					</List>
				</Collapse>
			</List>

			<Btn
				text={"Consent"}
				onClick={() => handleConsent()}
				style={{
					width: "200px",
					margin: "auto",
					marginBottom: "20px ",
				}}
			/>
		</Dialog>
	);
};

export default ConsentPage;
