import React, { useState, useEffect, FC } from "react";
import {
	Box,
	Button,
	TextField,
	Typography,
	CircularProgress,
	Alert,
	Paper,
	Divider,
} from "@mui/material";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
	emitClientSocketEvent,
	SOCKET_EVENTS,
	ClientPickupConfirmationResponse,
	OrderWithClient,
	Order,
	COLORS,
} from "shared";
import { Btn } from "ui/button";

const StaffPickupConfirmation: FC<{
	// setPickupApproved: (approved: boolean) => void;
	waitingResponse: boolean;
	requestConfirmation: () => void;
	responseData: ClientPickupConfirmationResponse | null;
	order: Order;
	cancelPickup: () => void;
}> = ({
	waitingResponse,
	requestConfirmation,
	responseData,
	order,
	cancelPickup,
}) => {
	const [pickupCode, setPickupCode] = useState("");
	const [pickupConfirmation, setPickupConfirmation] =
		useState<ClientPickupConfirmationResponse | null>(null);
	const [orderDetails, setOrderDetails] = useState<OrderWithClient>();

	useEffect(() => {
		// const socket = startClientSocket();
		// Listen for client response
		// onClientSocketEvent(
		//   SOCKET_EVENTS.pickup.clientPickupConfirmationResponse,
		//   (data: ClientPickupConfirmationResponse) => {
		//     setIsVerifying(false);
		//     setPickupConfirmation(data);
		//     setPickupApproved(data.approvePickup);
		//   }
		// );
	}, []);

	const handleQRCodeScan = () => {
		if (waitingResponse) return;

		emitClientSocketEvent(
			SOCKET_EVENTS.pickup.requestPickupConfirmationToClient,
			{
				method: "qr",
				orderNumber: "12345678",
			}
		);
	};

	const handleRequestConfirmation = () => {
		if (waitingResponse) return;
		// setIsVerifying(true);
		requestConfirmation();
	};

	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			className="body-container"
			width="600px"
			height={"500px"}
			maxHeight={"90%"}
			maxWidth={"90%"}
			margin={"auto"}
		>
			<Typography variant="h4" gutterBottom>
				Verify Order Pickup
			</Typography>

			{order.isDelivered ? <div></div> : <div></div>}
			{order && (
				<div style={{}}>
					<Typography variant="subtitle1">
						Order Number: #{order.orderNumber}
					</Typography>
					<Typography variant="subtitle1">Client: {order.firstname}</Typography>
					<Divider sx={{ my: 1 }} />
					<Typography variant="body2" color="text.secondary">
						Full details will be shown after client confirmation.
					</Typography>
				</div>
			)}

			{responseData && responseData.approvePickup ? (
				<Box display="flex" flexDirection="column" alignItems="center" mt={3}>
					<CheckCircleIcon color="success" sx={{ fontSize: 60 }} />
					<Typography variant="h5" color="success.main" mt={2}>
						Pickup approved
					</Typography>
					<Button
						variant="contained"
						color="primary"
						sx={{ mt: 3 }}
						onClick={() => window.location.reload()}
					>
						Return to Dashboard
					</Button>
				</Box>
			) : (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						gap: "50px",
						marginTop: "40px",
					}}
				>
					<Btn
						onClick={handleRequestConfirmation}
						text={"Verify"}
						isDisabled={waitingResponse}
					/>
					<Btn
						onClick={cancelPickup}
						text={"Cancel"}
						isDisabled={!waitingResponse}
					/>
				</div>
			)}

			{waitingResponse && <CircularProgress sx={{ mt: 3 }} />}

			{responseData && (
				<Alert
					severity={responseData.approvePickup ? "success" : "error"}
					sx={{ mt: 3 }}
				>
					{responseData.message}
				</Alert>
			)}
		</Box>
	);
};

export default StaffPickupConfirmation;
