import { Divider } from "@mui/material";
import { formatDistance } from "date-fns";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Client, Order, OrderStatus, OrderType, PaymentStatus } from "shared";
import { Btn } from "ui/button";
import CustomItemFlex from "ui/Items";
import { BodyWithClass } from "ui/typography";
import { COLORS, NairaSign } from "utils/constants";

const DashboardOrderDetail: FC<{ order: Order; client: Client }> = ({
  order,
  client,
}) => {
  const navigate = useNavigate();
  const handleStatusColor = (status: OrderStatus) => {
    if (status === OrderStatus.Processing) return COLORS.YellowLight;
    if (status === OrderStatus.Paid || status === OrderStatus.Delivered)
      return COLORS.StatusGreen;
    return "red";
  };

  const handleOrderType = (orderType: OrderType) => {
    if (orderType === OrderType.Gas) {
      return "Gas order";
    }
    if (orderType === OrderType.GasAndProduct) {
      return "Gas and Cylinder";
    }
    return "Cylinder";
  };
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <div>
        <BodyWithClass text={"Customer"} className={""} />
      </div>
      <div>
        <CustomItemFlex
          text={"customer : "}
          value={`${client.firstname} ${client.lastname}`}
          valueStyle={{ color: COLORS.GreyMediumDark }}
        />
        <CustomItemFlex
          text={"phone number : "}
          value={client.mobileNumber}
          valueStyle={{ color: COLORS.GreyMediumDark }}
        />
        <CustomItemFlex
          text={"Order Date:"}
          valueStyle={{ color: COLORS.GreyMediumDark }}
          value={formatDistance(new Date(order.createdAt), new Date(), {
            addSuffix: true,
          })}
        />
        <CustomItemFlex
          text={"Order Number : "}
          value={"# " + order.orderNumber}
          valueStyle={{ color: COLORS.GreyMediumDark }}
        />
        <CustomItemFlex
          text={"Status: "}
          value={order.status}
          valueStyle={{ color: handleStatusColor(order.status) }}
        />
        <CustomItemFlex
          valueStyle={{ color: COLORS.GreyMediumDark }}
          text={"Order type: "}
          value={handleOrderType(order.orderType)}
        />
      </div>
      <Divider />

      <div>
        {order.orderType !== OrderType.Product && (
          <div>
            <div>
              <BodyWithClass text={"Gas"} className={""} />
            </div>
            <CustomItemFlex
              valueStyle={{ color: COLORS.GreyMediumDark }}
              text={"Quantity: "}
              value={order.quantityKg + "Kg"}
            />
            <CustomItemFlex
              valueStyle={{ color: COLORS.GreyMediumDark }}
              text={"Price per kg: "}
              value={NairaSign + order.pricePerKg}
            />
            <CustomItemFlex
              valueStyle={{ color: COLORS.GreyMediumDark }}
              text={"Amount: "}
              value={NairaSign + order.gasAmount}
            />
          </div>
        )}
        <div>
          {order.product &&
            order.product.map((item, index) => (
              <div key={index}>
                <CustomItemFlex
                  text={"Item: "}
                  valueStyle={{ color: COLORS.GreyMediumDark }}
                  value={item.name}
                />
                <CustomItemFlex
                  text={"Quantity: "}
                  value={item.quantity}
                  valueStyle={{ color: COLORS.GreyMediumDark }}
                />
                <CustomItemFlex
                  text={"Size: "}
                  value={item.price}
                  valueStyle={{ color: COLORS.GreyMediumDark }}
                />
                <CustomItemFlex
                  text={"Price: "}
                  valueStyle={{ color: COLORS.GreyMediumDark }}
                  value={NairaSign + item.price}
                />
                <CustomItemFlex
                  text={"Amount: "}
                  valueStyle={{ color: COLORS.GreyMediumDark }}
                  value={NairaSign + item.total}
                />
              </div>
            ))}
        </div>
      </div>
      <div>
        <Divider />

        <div>
          <BodyWithClass text={"Delivery Option"} className={""} />
        </div>
        <CustomItemFlex
          text={"Delivered type :"}
          valueStyle={{ color: COLORS.GreyMediumDark }}
          value={order.deliveryOption}
        />
        {order.deliveredDate && (
          <CustomItemFlex
            text={"Delivered At :"}
            valueStyle={{ color: COLORS.GreyMediumDark }}
            value={formatDistance(new Date(order.createdAt), new Date(), {
              addSuffix: true,
            })}
          />
        )}
      </div>
      <div>
        <Divider />
        <div>
          <BodyWithClass text={"Action"} className={""} />
        </div>
        {order.paymentStatus !== PaymentStatus.Success ? (
          <div>
            <BodyWithClass text={"Order has not been paid"} className={""} />
            <Btn text={"Pay now!"} />
          </div>
        ) : (
          <div>
            {order.status === OrderStatus.Paid && (
              <div>
                <BodyWithClass text={"Order is paid"} className={""} />
                <Btn text={"Process order now "} />
              </div>
            )}
            {order.status === OrderStatus.Delivered && (
              <div>
                <BodyWithClass
                  text={"This Order has been delivered"}
                  className={""}
                />
                <CustomItemFlex
                  text={"Delivered At :"}
                  valueStyle={{ color: COLORS.GreyMediumDark }}
                  value={formatDistance(
                    new Date(order.deliveredDate as any),
                    new Date(),
                    {
                      addSuffix: true,
                    }
                  )}
                />{" "}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardOrderDetail;
