import { getOrderByNumberApi, verifyOrderPaymentApi } from "api/order";
import { ScreenType } from "interface";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Order, OrderPaymentVerification } from "shared";
import useScreenTypeStore from "store/screenType";
import Loading from "ui/isLoading";
import OrderDetail from "ui/order/OrderDetail";
import { BodyWithClass } from "ui/typography";

const OrderDetailPage = () => {
  const { number: id } = useParams();
  const [order, setOrder] = useState<Order>();
  const { screenType } = useScreenTypeStore();

  useEffect(() => {
    if (!id) return;
    getOrderByNumberApi(id)
      .then((response) => {
        setOrder(response);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <div
      style={{
        width: "700px",
        maxWidth: "calc(100% - 60px)",
        padding: "15px",
        margin: "auto",
      }}
    >
      {order ? (
        <div
          style={{
            width: "500px",
            maxWidth: "100%",
            margin: "auto",
            marginTop: screenType === ScreenType.Mobile ? "50px" : "100px",

            height: "100%",
          }}
        >
          <div style={{ marginBottom: "20px" }}>
            <BodyWithClass text={"Order summary"} className={"poppins-bold "} />
          </div>
          <div>
            <OrderDetail {...order} />
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default OrderDetailPage;
