export interface DeliveryStatusData {
  status: DeliveryStatus;
  date: Date;
  userType: DeliveryUserType;
  location: Location;
  description?: string;
}

export enum DeliveryUserType {
  Carrier = "Carrier",
  Client = "Client",
}
export enum DeliveryStatus {
  ClientAccepted = "Accepted",
  ClientRejected = "Rejected",
  CarrierPickup = "CarrierPickup",
  OrderNotDelivered = "OrderNotDelivered",
  CantLocateClientLocation = "CantLocateClientLocation",
  Other = "Other",
}

export enum DeliveryOption {
  PickUp = "PickerUP",
  HomeDelivery = "HomeDelivery",
  PickUpBySomeOne = "PickUpBySomeOne",
}
export enum HomeDeliveryType {
  Oneway = "oneWay",
  DoubleWay = "DoubleWay",
}

export interface DeliveryLocation {
  lat: number;
  long: number;
  address: string;
  description: string;
  homeImage?: string;
}

export interface SearchLocation {
  address: string;
  lat: number;
  long: number;
  placeId?: number;
}
export type DeliveryTime = "Now" | Date | null;

