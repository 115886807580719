import React, { useEffect, useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import { AxiosApi } from "api";
import {
	ClientPickupConfirmationResponse,
	ErrorType,
	Order,
	OrderWithClient,
	PickupEligibilityCheck,
	PickupEligibilityStatus,
	RequestPickupConfirmation,
	SOCKET_EVENTS,
	SOCKET_PICKUP_EVENTS_STAFF,
	emitClientSocketEvent,
	findOrderByPickupCodeApi,
	onClientSocketEvent,
	startClientSocket,
	verifyPickupEligibility,
} from "shared";
import Loading from "ui/isLoading";
import QRScanner from "ui/qrCode/QRCodeScanner";
import FindPickupOrder from "ui/dashboard/FindPickupOrder";
import { PickupType } from "interface";
import OrderProcessPage from "ui/dashboard/PickupPage";
import { useNavigate, useParams } from "react-router-dom";
import StaffPickupConfirmation from "ui/dashboard/PickupConfirmation";
import { useClientStore, useSnackbarStore } from "store";
import OrderAlreadyPicked from "ui/order/OrderAlreadyPickup";
import PickupEligibilityPage from "ui/dashboard/PickupEligiblityCheck";

const PickupOrdersPage = () => {
	const { code, codeType } = useParams();
	const [order, setOrder] = useState<Order>();
	const [foundOrder, setFoundOrder] = useState(false);
	const [pickupData, setPickupData] =
		useState<ClientPickupConfirmationResponse | null>(null);
	const [loadingData, setLoadingData] = useState(false);
	const [error, setError] = useState("");
	const [isVerifying, setIsVerifying] = useState(false);
	const [pickupEligibility, setPickupEligibility] =
		useState<PickupEligibilityStatus | null>(null);

	const { setSnackbar } = useSnackbarStore();

	const navigate = useNavigate();

	const { staff } = useClientStore();

	useEffect(() => {
		if (!code || !codeType) {
			return;
		}
		if (order) {
			return;
		}
		handleFindPickupOrder(code, codeType as any);
	}, []);

	useEffect(() => {
		const socket = startClientSocket();
		socket.on(
			SOCKET_PICKUP_EVENTS_STAFF.clientPickupConfirmationResponseStaff,
			(data: ClientPickupConfirmationResponse) => {
				console.log("response", data);
				setIsVerifying(false);

				setPickupData(data);
			}
		);
		onClientSocketEvent(
			SOCKET_PICKUP_EVENTS_STAFF.ClientOffLine,
			(data: ClientPickupConfirmationResponse) => {
				console.log("Client offline", data);
			}
		);

		onClientSocketEvent(SOCKET_EVENTS.pickup.orderNotFound, () => {
			console.log("Order not found");
		});
		onClientSocketEvent(SOCKET_PICKUP_EVENTS_STAFF.duplicatedRequest, () => {
			console.log("duplicated event");
		});

		onClientSocketEvent(SOCKET_EVENTS.pickup.pickupError, () => {
			console.log("Pickup error");
		});

		onClientSocketEvent(
			SOCKET_PICKUP_EVENTS_STAFF.ClientExperiencingError,
			() => {
				console.log("Pickup error");
			}
		);

		onClientSocketEvent(
			SOCKET_PICKUP_EVENTS_STAFF.pickupEligibilityStatus,
			(data: PickupEligibilityCheck) => {
				// if (data.checkStatus === PickupEligibilityStatus.AlreadyPickedUp) {
				// } else if (data.checkStatus === PickupEligibilityStatus.NotPaid) {
				// 	const severity = "warning";
				// 	const autoHide = null;

				// 	setSnackbar(data.message, severity, autoHide);
				// 	setPickupData({
				// 		...Object(),
				// 		message: data.message,
				// 		approvePickup: false,
				// 	});
				// 	setIsVerifying(false);
				// }
				setPickupEligibility(data.checkStatus);
			}
		);

		onClientSocketEvent(SOCKET_PICKUP_EVENTS_STAFF.StaffApproveOwnOrder, () => {
			const message = "You cant approve your own order while working";
			const severity = "warning";
			const autoHide = null;

			setSnackbar(message, severity, autoHide);
			setError(message);
			setPickupData({ ...Object(), message, approvePickup: false });
			setIsVerifying(false);
		});

		return () => {
			console.log("is disconnecting");
			socket.disconnect();
		};
	}, []);

	const handleFindPickupOrder = (code: string, searchType: PickupType) => {
		// getPickupOrdersApi(AxiosApi)
		//   .then((response) => setOrders(response))
		//   .catch((err) => console.error(err))
		//   .finally(() => setLoading(false));
		setLoadingData(true);

		if (searchType === PickupType.PickupCode) {
			findOrderByPickupCodeApi(AxiosApi, code)
				.then((response) => {
					response.isDelivered = false;
					// const eligibilityCheck = verifyPickupEligibility(response);
					// if (eligibilityCheck !== PickupEligibilityStatus.Eligible) {
					// 	setOrder(response);

					// 	setPickupEligibility(eligibilityCheck);
					// 	return;
					// }
					setFoundOrder(true);

					setOrder(response);
					navigate(`/dashboard/pickup/${code}/${searchType}`);
				})
				.catch((err: any) => {
					if (err.type === ErrorType.InvalidPickupCode) {
						setError(
							"The pickup code you enter is invalid  ask the customer for another code"
						);
						return;
					}
					setError("Something went wrong, please connect support ");
				})
				.finally(() => {
					setLoadingData(false);
				});
		}
	};

	const handleRequestConfirmation = () => {
		if (!order) return;
		const data: RequestPickupConfirmation = {
			pickupCode: code as any,
			orderId: order?.id as any,
			staffId: staff?.id as any,
			codeType: codeType as any,
			clientId: order?.clientId,
		};
		console.log("requesting confirmation", data);
		setIsVerifying(true);
		emitClientSocketEvent(SOCKET_EVENTS.pickup.requestConfirmationStaff, data);
	};
	const handleCancelledRequest = () => {
		setIsVerifying(false);
		if (!order) return;
		const data: RequestPickupConfirmation = {
			pickupCode: code as any,
			orderId: order?.id as any,
			staffId: staff?.id as any,
			codeType: codeType as any,
			clientId: order?.clientId,
		};
		emitClientSocketEvent(SOCKET_PICKUP_EVENTS_STAFF.StaffCancelRequest, data);
	};

	const handleApprovedRequest = () => {
		if (!order) return;
		if (!staff) return;

		emitClientSocketEvent(SOCKET_PICKUP_EVENTS_STAFF.staffConfirmApproval, {
			staffId: staff.id,
			clientId: order.clientId,
			orderId: order.id,
		});
	};

	if (
		pickupEligibility &&
		pickupEligibility !== PickupEligibilityStatus.Eligible
	) {
		return (
			<PickupEligibilityPage
				order={order as any}
				status={pickupEligibility}
				onAction={() => {
					setPickupEligibility(null);
					setOrder(undefined);
				}}
			/>
		);
	}
	return (
		<Box sx={{ p: 3 }}>
			{!order ? (
				<div>
					<FindPickupOrder
						searchOrder={handleFindPickupOrder}
						codeMatch={foundOrder}
						verificationMessage={error}
						loading={loadingData}
					/>
				</div>
			) : (
				<div>
					{order.isDelivered ? (
						<OrderAlreadyPicked
							{...order}
							action={{
								title: "Close",
								onClick: () => {
									setOrder(undefined);
								},
							}}
						/>
					) : (
						<>
							{pickupData?.approvePickup ? (
								<OrderProcessPage
									{...order}
									confirmPickup={handleApprovedRequest}
								/>
							) : (
								<StaffPickupConfirmation
									requestConfirmation={handleRequestConfirmation}
									order={order}
									responseData={pickupData}
									waitingResponse={isVerifying}
									cancelPickup={handleCancelledRequest}
								/>
							)}
						</>
					)}
				</div>
			)}
		</Box>
	);
};

export default PickupOrdersPage;
