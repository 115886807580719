import { isAxiosError, AxiosInstance } from "axios";
import {
  Order,
  OrderApiFilterParam,
  OrderPaymentVerification,
  GasOrderForm,
  OrderWithClient,
  PickupOrder,
} from "../interface";
import { API_PATH } from "../apiPath";

export const newOrderApi = async (
  axiosInstance: AxiosInstance,
  form: GasOrderForm
) => {
  try {
    const _data = await axiosInstance.post<Order>(
      API_PATH.order.newOrder.frontend,
      form
    );
    return _data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
 };

export const getOrderByClientApi = async (
  axiosInstance: AxiosInstance,
  filter: OrderApiFilterParam
) => {
  try {
    const _data = await axiosInstance.get<Order[]>(
      API_PATH.order.getOrdersByClient.frontend,
      {
        params: {
          ...filter,
        },
      }
    );
    return _data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
 };

export const getOrderByNumberApi = async (
  axiosInstance: AxiosInstance,
  orderNumber: string
) => {
  try {
    const _data = await axiosInstance.get<Order>(
      API_PATH.order.getOrderByNumber(orderNumber).frontend
    );
    return _data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
 };

export const cancelOrderByIdApi = async (
  axiosInstance: AxiosInstance,
  orderId: string
) => {
  try {
    const _data = await axiosInstance.get<Order>(`/order/cancel/${orderId}`);
    return _data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
 };

export const confirmOrderPaymentApi = async (
  axiosInstance: AxiosInstance,
  orderId: string
) => {
  try {
    const _data = await axiosInstance.get<Order>(
      `/order/confirm_payment/${orderId}`
    );
    return _data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
 };

export const verifyOrderPaymentApi = async (
  axiosInstance: AxiosInstance,
  orderNumber: number
) => {
  try {
    const _data = await axiosInstance.get<OrderPaymentVerification>(
      API_PATH.order.verifyOrderPayment(orderNumber).frontend
    );
    return _data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
 };
export const getOrderByPaymentNumberApi = async (
  axiosInstance: AxiosInstance,
  paymentNumber: number
) => {
  try {
    const _data = await axiosInstance.get<Order>(
      `/order/payment_number/` + paymentNumber
    );
    return _data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
 };

export const getFilteredOrdersApi = async (
  axiosInstance: AxiosInstance,
  filter: OrderApiFilterParam
) => {
  try {
    const _data = await axiosInstance.get<Order[]>(
      API_PATH.order.filterOrders.frontend,
      { params: filter }
    );
    return _data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
 };

export const getOrderWithClientApi = async (
  axiosInstance: AxiosInstance,
  orderNumber: number
) => {
  try {
    const _data = await axiosInstance.get<OrderWithClient>(
      API_PATH.order.orderWithClient(orderNumber).frontend
    );
    return _data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
 };

export const searchOrderWithClientApi = async (
  axiosInstance: AxiosInstance,
  orderNumber: number
) => {
  try {
    const _data = await axiosInstance.get<OrderWithClient>(
      API_PATH.order.orderWithClient(orderNumber).frontend
    );
    return _data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
 };

export const pickupOrderApi = async (
  axiosInstance: AxiosInstance,
  pickupCode: string
) => {
  try {
    const _data = await axiosInstance.get<PickupOrder>(
      API_PATH.order.pickupOrder(pickupCode).frontend
    );
    return _data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
 };