import React, { useState, useEffect, FC } from "react";
import {
	Box,
	Typography,
	Button,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	Card,
	CardContent,
	Chip,
	Divider,
} from "@mui/material";
import { CLASSNAMES, Order } from "shared";
import CustomItemFlex from "ui/Items";
import { BodyStrong } from "ui/typography";

const OrderProcessPage: FC<Order & { confirmPickup: () => void }> = ({
	confirmPickup,
	...order
}) => {
	const [deliveryOption, setDeliveryOption] = useState(order.deliveryOption);

	useEffect(() => {
		// setDeliveryOption(order.deliveryOption);
		// setGasQuantity(order.gasQuantity);
		// setCylinderStatus(order.hasCylinder);
	}, [order]);

	const handleDeliveryChange = (event: any) => {
		setDeliveryOption(event.target.value);
	};

	const handleConfirmOrder = () => {
		confirmPickup();
		// Handle order confirmation logic here
	};

	return (
		<Box padding={4} className={CLASSNAMES.containerBackground}>
			<Typography variant="h4" gutterBottom>
				Process Order #{order.orderNumber}
			</Typography>

			<div style={{ marginBottom: 2 }}>
				<CustomItemFlex
					text={"Gas Quantity"}
					value={order.quantityKg + " Kg"}
				/>
				<CustomItemFlex text={"Payment Status"} value={order.paymentStatus} />
				<CustomItemFlex text={"Delivery Option"} value={order.deliveryOption} />
				<CustomItemFlex text={"Order status"} value={order.status} />
				<Divider />
				<BodyStrong text={"Cylinder information"} />
				<CustomItemFlex text={"Customer cylinder"} value={order} />
			</div>

			<Card variant="outlined" sx={{ marginBottom: 2 }}>
				<CardContent>
					<Typography variant="h6">Cylinder Status:</Typography>
					{/* {? (
						<Chip label="Customer has a cylinder" color="success" />
					) : (
						<Chip
							label="Customer must bring their own cylinder"
							color="warning"
						/>
					)} */}
				</CardContent>
			</Card>

			<Button variant="contained" color="primary" onClick={handleConfirmOrder}>
				Confirm Order
			</Button>
		</Box>
	);
};

export default OrderProcessPage;
