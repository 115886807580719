import { Client, Staff } from ".";
import { DeliveryOption } from "./delivery";
import { PaymentStatus, PaymentType } from "./payment";
import { Product } from "./product";

export enum OrderPickUpDispute {
  None = "none",
  SomeOneElseClaim = "SomeOneElseClaim",
  InvalidPickup = "InvalidPickup ",
}

export interface OrderWithClient {
  client: Client;
  order: Order;
}

export interface PickupOrder {}
export interface PickupData extends OrderWithClient {}
export interface Order {
  id: string;
  clientId: string;
  paymentId: number;
  orderNumber: number;
  qrCodeToken: string;
  clientMobileNumber?: string;
  firstname?: string;
  lastname?: string;
  payment: any;
  paymentType: PaymentType;
  paymentStatus: PaymentStatus;
  paymentSuccess: boolean;
  paymentNumber: number;
  pricePerKg: number;
  quantityKg: number;
  totalAmount: number;
  isDelivered: boolean;
  issuedById: string;
  pickupCode: string;
  orderType: OrderType;
  // deliveredBy: String;
  product?: Product[];
  gasAmount?: number;
  productAmount?: number;
  deliveredDate?: Date;
  deliveryOption: DeliveryOption;
  shouldDeliverAt: Date;
  deliveryCenter: DeliverCenters;
  pickupDispute: OrderPickUpDispute;
  createdAt: Date;
  status: OrderStatus;
  clientConfirmedPickup: boolean;
  clientConfirmPickupDate: Date;
  processedByStaff?: Staff
}

export interface OrderApiFilterParam {
  clientId?: string;
  orderStatus?: OrderStatus | "All";
  notEqualToOrderStatus?: OrderStatus;
  fromDate?: Date;
  toDate?: Date;
  paymentStatus?: PaymentStatus | "All";
  paymentSuccess?: boolean;
  staffId?: string[];
  orderNumber?: number;
  clientMobileNumber?: string;
  take?: number;
  skip?: number;
}
export enum OrderType {
  Gas = "Gas",
  Product = "Product",
  GasAndProduct = "Gas and Product",
}
export enum DeliverCenters {
  Company = "Urbanline_gas",
}

export enum OrderStatus {
  NotPaid = "Not Paid",
  Processing = "Processing",
  Paid = "Paid",
  Fail = "Failed",
  Delivered = "Delivered",
  Cancelled = "Cancelled",
  ClientRejectPickup = "ClientRejectPickup",
  StaffRejectPickup = "StaffRejectPickup"
}

export enum PickupAttemptStatus {
  SUCCESS = "success",
  FAILED = "failed",
  BLOCKED = "blocked",
  EXPIRED = "expired",
  INVALID = "invalid",
  PENDING = "pending",
}
