import { create } from "zustand";

interface SnackbarAction {
    text: string;
    onClick: () => void;
    textColor?: string;
}

interface SnackbarStore {
    open: boolean;
    message: string;
    autoHideDuration: number | null;
    actions?: SnackbarAction[];
    severity: "success" | "error" | "warning" | "info";
    setSnackbar: (message: string, severity: "success" | "error" | "warning" | "info", autoHideDuration: number | null, actions?: SnackbarAction[]) => void;
    closeSnackbar: () => void;
}

export const useSnackbarStore = create<SnackbarStore>((set) => ({
    open: false,
    message: "",
    severity: "info",
    autoHideDuration: 6000, // Default duration set to 6000 milliseconds
    setSnackbar: (message, severity, autoHideDuration = 6000, actions) =>
        set(() => ({
            open: true,
            message,
            severity,
            autoHideDuration,
            actions
        })),
    closeSnackbar: () =>
        set(() => ({
            open: false,
            message: "",
        })),
}));
