import {
	addFundToWalletApi,
	getWalletApi,
	getWalletDetailsApi,
	getWalletTransactionsApi,
} from "api/wallet";
import React, { useEffect, useState } from "react";
import { PaymentPurpose, Wallet, WalletTransaction } from "shared";
import { useWalletStore } from "store";
import {
	SyncAlt,
	AddCard,
	ArrowDownward,
	TuneOutlined,
} from "@mui/icons-material";

import { Btn, BtnIcon } from "ui/button";
import Loading from "ui/isLoading";
import { WalletCard } from "ui/wallet/WalletCard";
import WalletInfo from "ui/wallet/WalletInfo";
import WalletHistoryCard from "ui/wallet/WalletHistoryCard";
import { BodyWithClass } from "ui/typography";
import { IconButton } from "@mui/material";
import { InputField } from "ui/inputs";
import { formatNigeriaNumber } from "utils/helper";
import { NairaSign } from "utils/constants";
import { useNavigate } from "react-router-dom";
import { usePaymentStore } from "store/payment";
import { da } from "date-fns/locale";

enum ShowPageType {
	Transactions = "Transactions",
	AddFund = "AddFund",
	Withdraw = "Withdraw",
	Transfer = "Transfer",
}
const WalletPage = () => {
	const [walletTransactions, setWalletTransactions] = useState<
		WalletTransaction[]
	>([]);
	const [loading, setLoading] = useState(true);
	const [showConfirmAddFund, setShowConfirmAddFund] = useState(false);
	const [showPageType, setShowPageType] = useState(ShowPageType.Transactions);
	const [showWalletDetails, setShowWalletDetails] = useState(false);
	const [addFundAmount, setAddFundAmount] = useState(0);

	const { wallet, setWallet } = useWalletStore();
	const { setPaymentDetails } = usePaymentStore();
	const navigate = useNavigate();

	useEffect(() => {
		getWalletApi()
			.then((data) => {
				setWallet(data);
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [loading]);

	// const getWalletTransactions = () => {
	// 	getWalletTransactionsApi()
	// 		.then((data) => {
	// 			setWalletTransactions(data);
	// 		})
	// 		.catch((err) => {
	// 			console.log(err);
	// 		});
	// };

	const handleShowDetail = () => {
		getWalletDetailsApi()
			.then((data) => {
				setWallet(data);
			})
			.catch((err) => {
				console.log(err);
			});
		setShowWalletDetails(!showWalletDetails);
	};

	const handleAddFundToWallet = () => {
		addFundToWalletApi(addFundAmount)
			.then((data) => {
				setPaymentDetails({
					authorizationUrl: data.authorizationUrl,
					orderNumber: null,
					ref: data.paymentReference,
					accessCode: data.accessCode,
					purpose: PaymentPurpose.FundWallet,
					paymentNumber: data.paymentNumber as any,
				});
				navigate({
					pathname: `/payment/redirect`,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const handleWithdrawFromWallet = () => {};

	if (loading) return <Loading />;
	return (
		<div
			style={{
				maxWidth: "100%",
				width: "600px",
				margin: "auto",
			}}
		>
			{wallet ? (
				<div style={{ paddingTop: "30px" }}>
					<div
						style={{
							width: "450px",
							maxWidth: "90%",
							borderRadius: "19px",
							margin: "auto",
						}}
					>
						<WalletCard
							{...wallet}
							showDetails={showWalletDetails}
							setShowDetail={handleShowDetail}
						/>
					</div>
					<div
						style={{
							width: "450px",
							maxWidth: "90%",
							display: "flex",
							borderRadius: "10px",
							justifyContent: "space-between",
							padding: "10px",
							margin: "auto",
							opacity: "0.9",
							marginTop: "20px",
							background: "linear-gradient(135deg, #1a237e 0%, #0d47a1 100%)",
							marginBottom: "40px",
						}}
					>
						<BtnIcon
							Icon={AddCard}
							iconColor={"white"}
							name={"Add fund "}
							backgroundColor="white"
							onClick={() => setShowPageType(ShowPageType.AddFund)}
						/>
						<BtnIcon
							Icon={ArrowDownward}
							iconColor={"white"}
							name={"Withdraw"}
							isDisabled={true}
						/>
						<BtnIcon
							Icon={SyncAlt}
							iconColor={"white"}
							name={"Transfer"}
							isDisabled={true}
						/>
					</div>

					<div>
						{showPageType === ShowPageType.Transactions && (
							<>
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
										marginTop: "30px",
									}}
								>
									<BodyWithClass text={"Transaction"} className={""} />
									<IconButton>
										<TuneOutlined />
									</IconButton>
								</div>
								<div style={{}}>
									{walletTransactions.map((tr) => (
										<WalletHistoryCard key={tr.id} {...tr} />
									))}
								</div>
							</>
						)}
						{showPageType === ShowPageType.AddFund && (
							<div>
								<BodyWithClass text={"Add Fund"} className={""} />
								<div>
									{!showConfirmAddFund ? (
										<>
											<InputField
												label={"Amount"}
												type={"number"}
												onChange={(value) => setAddFundAmount(value as any)}
											/>

											<Btn
												text={"Add Fund"}
												onClick={() => setShowConfirmAddFund(true)}
												isDisabled={addFundAmount === 0}
											/>
										</>
									) : (
										<div>
											<div
												style={{
													backgroundColor: "gray",
													opacity: "0.6",
													padding: "10px",
													borderRadius: "10px",
													color: "white",
												}}
											>
												<div
													style={{
														display: "flex",
														justifyContent: "space-between",
													}}
												>
													<BodyWithClass text={"Amount"} className={""} />
													<BodyWithClass
														text={
															NairaSign +
															"" +
															formatNigeriaNumber(addFundAmount)
														}
														className={""}
													/>
												</div>
												<div
													style={{
														display: "flex",
														justifyContent: "space-between",
													}}
												>
													<BodyWithClass
														text={"wallet number"}
														className={""}
													/>
													<BodyWithClass
														text={wallet.walletNumber as any}
														className={""}
													/>
												</div>
											</div>
											<div
												style={{
													display: "flex",
													justifyContent: "center",
													gap: "50px",
													marginTop: "20px",
												}}
											>
												<Btn
													text={"Edit Amount"}
													onClick={() => setShowConfirmAddFund(false)}
												/>
												<Btn
													text={"Confirm amount"}
													onClick={handleAddFundToWallet}
													isDisabled={addFundAmount === 0}
												/>
											</div>
										</div>
									)}
								</div>
							</div>
						)}

						{showPageType === ShowPageType.Withdraw && (
							<div>
								<BodyWithClass text={"Withdraw"} className={""} />
							</div>
						)}
						{showPageType === ShowPageType.Transfer && (
							<div>
								<BodyWithClass text={"Transfer"} className={""} />
							</div>
						)}
					</div>
				</div>
			) : (
				<div style={{}}>
					<WalletInfo setLoading={() => setLoading(true)} />
				</div>
			)}
		</div>
	);
};

export default WalletPage;
