import { isAxiosError } from "axios";
import { API_PATH, StaffForm } from "shared";
import { AxiosApi } from ".";

export const createStaffApi = async (form: StaffForm): Promise<any> => {
  try {
    const data = await AxiosApi.post<any>(API_PATH.staff.create.frontend, form);
    return data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};
