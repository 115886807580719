const payment = "/payment";

type ApiPath = {
  frontend: string;
  backend: string;
};

export const PaymentPath = {
  payment,
};

const api_prefix = "api";

const handlePath = ({ path }: { path: string }) => {
  const apiPath: ApiPath = {
    frontend: `${api_prefix}${PaymentPath.payment}/${path}`,
    backend: `/${path}`,
  };
  return apiPath;
};

export const Payment_API_PATH = {
  walletAddFund: handlePath({
    path: `initiate/add_found`,
  }),
  orderPayment: handlePath({
    path: `initiate/order`,
  }),
  list: handlePath({
    path: `/`,
  }),

  initiate: handlePath({
    path: "initiate",
  }),

  paystackRetryPayment: handlePath({
    path: "paystack/retry_payment",
  }),

  sendPayment: handlePath({
    path: "send_payment",
  }),

  paymentByWallet: handlePath({
    path: `wallet/`,
  }),

  getPaymentByOrder: (orderId: string) =>
    handlePath({
      path: `order/${orderId}`,
    }),

  getByPaymentNumber: (paymentNumber: string) =>
    handlePath({
      path: `number/${paymentNumber}`,
    }),

  verifyPayment: (paymentNumber: string) =>
    handlePath({
      path: `verify/${paymentNumber}`,
    }),

  getPayment: (id: string) =>
    handlePath({
      path: `${id}`,
    }),
  getPaymentUsableData: handlePath({
    path: `usable`,
  }),

  discardPaymentUsableRedis: handlePath({
    path: `discard_redish`,
  }),

  savePaymentUsableData: handlePath({
    path: `save_usable`,
  }),
};
