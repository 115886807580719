import React from "react";
import { Snackbar, Alert, Button, IconButton, Stack } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useSnackbarStore } from "store";

const SnackbarWithCloseButton = () => {
	const { open, message, severity, autoHideDuration, actions, closeSnackbar } =
		useSnackbarStore();

	return (
		<Snackbar
			open={open}
			autoHideDuration={autoHideDuration}
			onClose={closeSnackbar}
			anchorOrigin={{ vertical: "top", horizontal: "center" }}
		>
			<Alert
				onClose={closeSnackbar}
				severity={severity}
				sx={{ width: "100%", display: "flex", alignItems: "center" }}
				action={
					<Stack direction="row" spacing={1} alignItems="center">
						{actions?.map((action, index) => (
							<Button
								key={index}
								size="small"
								onClick={action.onClick}
								sx={{
									color: action.textColor || "inherit",
									textTransform: "none",
								}}
							>
								{action.text}
							</Button>
						))}
						<IconButton
							size="small"
							color="inherit"
							onClick={closeSnackbar}
							aria-label="close"
						>
							<CloseIcon fontSize="small" />
						</IconButton>
					</Stack>
				}
			>
				{message}
			</Alert>
		</Snackbar>
	);
};

export default SnackbarWithCloseButton;
