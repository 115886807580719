import React, { FC } from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { Btn, ButtonProps } from "./button";

const Info: FC<{
	title: string;
	show: boolean;
	setShow: () => void;
	description: string;
	subDescription?: string;
	actions: ButtonProps[];
}> = ({
	title,
	show,
	setShow,
	actions = [],
	description,
	subDescription = "",
}) => {
	return (
		<Dialog open={show} onClose={setShow}>
			<DialogTitle>
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<span>{title}</span>
					<IconButton onClick={setShow} aria-label="close">
						<CloseIcon color="warning" />
					</IconButton>
				</div>
			</DialogTitle>
			<DialogContent>
				<p>{description}</p>
				{subDescription && <p>{subDescription}</p>}
			</DialogContent>
			<DialogActions
				style={{
					display: "flex",
					justifyContent: actions.length > 1 ? "space-between" : "center",
				}}
			>
				{actions.map((action, index) => (
					<div key={index}>
						<Btn {...action} />
					</div>
				))}
			</DialogActions>
		</Dialog>
	);
};

export default Info;
