import React from "react";
import { Route } from "react-router-dom";
import ProtectedRoute from "./ProtectedRout";
import DashboardLayoutPage from "components/DashboardLayout";
import NewStaff from "components/dashboardPages/staff/NewStaff";
import AccessDenied from "components/AccessDenied";
import { StaffAccess } from "shared";
import DashboardOrderHistory from "components/dashboardPages/order/Orders";
import DashboardOrderDetailPage from "components/dashboardPages/order/OrderDetail";
import PickupOrdersPage from "components/dashboardPages/pickup/ClientPickup";
import StaffShiftPage from "components/dashboardPages/shift/Shift";

const DashboardRoutes = (
  <Route
    path="/dashboard"
    element={
      <ProtectedRoute requiredAccess={[StaffAccess.All]}>
        <DashboardLayoutPage />
      </ProtectedRoute>
    }
  >
    {/* <Route index element={<DashboardHome />} /> */}
    <Route path="order/list" element={<DashboardOrderHistory />} />
    <Route
      path="order/detail/:orderNumber"
      element={<DashboardOrderDetailPage />}
    />
    <Route path="order/detail" element={<DashboardOrderDetailPage />} />

    <Route
      path="staff/create"
      element={
        <ProtectedRoute requiredAccess={[StaffAccess.CEO, StaffAccess.Manager]}>
          <NewStaff />
        </ProtectedRoute>
      }
    />
    <Route
      path="pickup"
      element={
        <ProtectedRoute requiredAccess={[StaffAccess.All]}>
          <PickupOrdersPage />
        </ProtectedRoute>
      }
    />
    <Route
      path="shift"
      element={
        <ProtectedRoute requiredAccess={[StaffAccess.All]}>
          <StaffShiftPage />
        </ProtectedRoute>
      }
    />
    <Route
      path="pickup/:code/:codeType"
      element={
        <ProtectedRoute requiredAccess={[StaffAccess.All]}>
          <PickupOrdersPage />
        </ProtectedRoute>
      }
    />
    <Route path="access-denied" element={<AccessDenied />} />
    <Route path="*" element={<div>Page not found</div>} />

    {/* <Route path="staff" element={<Staff />} /> */}
  </Route>
);

export default DashboardRoutes;
