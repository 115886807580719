import { isAxiosError } from "axios";
import {
  GasOrderForm,
  Order,
  Payment,
  PaymentInitData,
  PaymentUsableData,
  PaystackRetry,
  API_PATH,
  PaymentVerification,
} from "shared";
import { AxiosApi } from ".";

export const paymentInitApi = async (form: GasOrderForm) => {
  try {
    const _data = await AxiosApi.post<PaymentInitData>(
      API_PATH.payment.initiate.frontend,
      form
    );
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const retryPaymentPaystackApi = async (form: PaystackRetry) => {
  try {
    const _data = await AxiosApi.post<PaymentInitData>(
      API_PATH.payment.paystackRetryPayment.frontend,
      form
    );
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const getPaymentByOrderApi = async (orderId: string) => {
  try {
    const _data = await AxiosApi.get<Payment>(`/payment/order/${orderId}`);
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const getPaymentByApi = async () => {
  try {
    const _data = await AxiosApi.get<Payment>(`/payment/`);
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const orderPaymentByWalletApi = async (form: GasOrderForm) => {
  try {
    const _data = await AxiosApi.post<Order>(`/payment/wallet`, form);
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const getByPaymentNumberApi = async (paymentNumber: string) => {
  try {
    const _data = await AxiosApi.get<Payment>(
      API_PATH.payment.getByPaymentNumber(paymentNumber).frontend
    );
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const verifyPaymentApi = async (paymentNumber: string ) => {
  try {
    const _data = await AxiosApi.get<PaymentVerification>(
      API_PATH.payment.verifyPayment(paymentNumber ).frontend
    );
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const getPaymentUsableDataApi = async () => {
  try {
    const _data = await AxiosApi.get<PaymentUsableData | null>(
      API_PATH.payment.getPaymentUsableData.frontend
    );
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const savePaymentUsableDataApi = async () => {
  try {
    const _data = await AxiosApi.put(
      API_PATH.payment.savePaymentUsableData.frontend
    );
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};

export const discardPaymentUsableRedisDataApi = async () => {
  try {
    const _data = await AxiosApi.put(
      API_PATH.payment.discardPaymentUsableRedis.frontend
    );
    return _data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};
