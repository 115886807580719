export enum ScreenType {
    Mobile = "Mobile",
    Tablet = "Tablet",
    Desktop = "Desktop",
  }
  

 export  enum PickupType {
    QrCode = "QrCode",
    OrderNumber = "OrderNumber",
    PickupCode = "PickupCode",
  }
  