import { AxiosInstance, isAxiosError } from "axios";
import { Shift, Staff, StaffForm } from "../interface";
import { API_PATH } from "../apiPath";



export const startShiftApi = async (
  axiosInstance: AxiosInstance
): Promise<any> => {
  try {
    const data = await axiosInstance.post<Shift>(
      API_PATH.shift.startShift.frontend
    );
    return data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
};

export const endShiftApi = async (
  axiosInstance: AxiosInstance 
): Promise<Shift> => {  
  try {
    const data = await axiosInstance.post<Shift>(
      API_PATH.shift.endShift.frontend
    );
    return data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
};

export const getShiftApi = async (
  axiosInstance: AxiosInstance,
  id: string
): Promise<Shift | null> => {
  try {
    const data = await axiosInstance.get<Shift | null>(
      API_PATH.shift.getShift(id).frontend
    );
    return data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
};

export const getActiveShiftApi = async (
  axiosInstance: AxiosInstance 
): Promise<Shift | null> => {
  try {
    const data = await axiosInstance.get<Shift | null>(
      API_PATH.shift.getActiveShift.frontend
    );
    return data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
};

export const getTodayShiftApi = async (
  axiosInstance: AxiosInstance 
): Promise<Shift | null> => {
  try {
    const data = await axiosInstance.get<Shift | null>(
      API_PATH.shift.getActiveShift.frontend
    );
    return data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
};

export const getActiveShiftsApi = async (
  axiosInstance: AxiosInstance 
): Promise<Shift[]> => {
  try {
    const data = await axiosInstance.get<Shift[]>(
      API_PATH.shift.getActiveShifts.frontend
    );
    return data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
};

export const getShiftByStaffApi = async (
  axiosInstance: AxiosInstance,
  staffId: string   
): Promise<Shift | null> => {
  try {
    const data = await axiosInstance.get<Shift | null>(
      API_PATH.shift.getShiftByStaff(staffId).frontend
    );
    return data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
};
