import React from "react";
import { Card, CardContent, Typography, Button } from "@mui/material";
import { CheckCircle, AlertTriangle, XCircle } from "lucide-react";
import { Order, PickupEligibilityStatus } from "shared";
import OrderAlreadyPicked from "ui/order/OrderAlreadyPickup";
import { Btn } from "ui/button";
import CustomItemFlex from "ui/Items";
import { NairaSign } from "utils/constants";

interface PickupEligibilityPageProps {
	order: Order;
	status: PickupEligibilityStatus;
	onAction: (status: PickupEligibilityStatus) => void;
}

const PickupEligibilityPage: React.FC<PickupEligibilityPageProps> = ({
	order,
	status,
	onAction,
}) => {
	console.log(order);
	const renderContent = () => {
		switch (status) {
			case PickupEligibilityStatus.Eligible:
				return (
					<>
						<CheckCircle
							size={40}
							color="#4caf50"
							style={{ marginBottom: 8 }}
						/>
						<Typography variant="h6" color="success.main" gutterBottom>
							Order is Eligible for Pickup
						</Typography>
						<Typography variant="body1" gutterBottom>
							Order Number: {order.orderNumber}
						</Typography>
					</>
				);

			case PickupEligibilityStatus.AlreadyPickedUp:
				return (
					<div>
						<OrderAlreadyPicked
							{...order}
							action={{
								title: "Close ",
								onClick: () => {
									onAction(PickupEligibilityStatus.AlreadyPickedUp);
								},
							}}
						/>
					</div>
				);

			case PickupEligibilityStatus.NotPaid:
				return (
					<>
						<XCircle size={40} color="#f44336" style={{ marginBottom: 8 }} />
						<Typography variant="h6" color="error.main" gutterBottom>
							Order is not yet been paid
						</Typography>
						<Typography variant="body1" gutterBottom>
							This order has not been paid for yet.
						</Typography>
						<div>
							<CustomItemFlex
								text={"Payment Status"}
								value={order.paymentStatus}
							/>
							<CustomItemFlex
								text={"Amount"}
								value={NairaSign + order.totalAmount}
							/>
						</div>

						<div>
							<Btn text={"Pay order now"} />
						</div>
					</>
				);

			case PickupEligibilityStatus.DisputeExists:
				return (
					<>
						<XCircle size={40} color="#f44336" style={{ marginBottom: 8 }} />
						<Typography variant="h6" color="error.main" gutterBottom>
							Dispute Exists
						</Typography>
						<Typography variant="body1" gutterBottom>
							There is a dispute associated with this order.
						</Typography>
						<div>
							<Btn
								text={"Close now "}
								onClick={() => {
									onAction(PickupEligibilityStatus.DisputeExists);
								}}
							/>
						</div>
					</>
				);

			case PickupEligibilityStatus.Error:
			default:
				return (
					<>
						<XCircle size={40} color="#f44336" style={{ marginBottom: 8 }} />
						<Typography variant="h6" color="error.main" gutterBottom>
							Error
						</Typography>
						<Typography variant="body1" gutterBottom>
							An error occurred while checking pickup eligibility.
						</Typography>
						<div>
							<Btn
								text={"Close now "}
								onClick={() => {
									onAction(PickupEligibilityStatus.Error);
								}}
							/>
						</div>
					</>
				);
		}
	};

	return (
		<div
			style={{
				maxWidth: 600,
				margin: "16px auto",
				padding: 6,
				textAlign: "center",
			}}
		>
			<div>{renderContent()}</div>
		</div>
	);
};

export default PickupEligibilityPage;
