export enum StaffAccess {
  Admin = "Admin",
  Staff = "Staff",
  Manager = "Manager",
  CEO = "CEO",
  Accountant = "Accountant",
  All = "All",
}

export interface Staff {
  badgeNumber: number;
  // _id: string;
  id: string;
  firstname: string;
  lastname: string;
  previousWorkDate: Date;
  accountNumber: string;
  mobileNumber: string;
  employedDate: Date;
  access: StaffAccess[];
  clientId: string;
  isCurrentStaff: boolean;
  staffReview: string;
  roles: StaffRole[];
}

export interface StaffForm {
  firstname: string;
  lastname: string;
  accountNumber?: string;
  mobileNumber: string;
  access: StaffAccess[];
  email?: string;
}

export interface PickUpData {
  orderId: number;
  name: string;
  mobileNumber: string;
}

export interface StaffShiftData {
  badgeNumber: number;
  startTime: Date;
  endTime: Date;
  totalHours: number;
  message: string;
  workingNow: boolean;
  startingQuantity: number;
  closingQuantity: Number;
  TotalSalesQuantity: number;
  pricePerKg: number;
  shift: Shift;
}
export interface Shift {
  id: any;
  startTime: Date;
  endTime: Date;
  totalHours: number;
  overTimeHours: number;
  message: string;
  workingNow: boolean;
}

export enum StaffRole {
  ADMIN = "Admin",
  MANAGER = "Manager",
  SALES_ASSOCIATE = "Sales Associate",
  SHIFT_SUPERVISOR = "Shift Supervisor",
  CASHIER = "Cashier",
  CUSTOMER_SERVICE = "Customer Service",
  INVENTORY_SPECIALIST = "Inventory Specialist",
  DELIVERY_PERSON = "Delivery Person",
  SOFTWARE_ENGINEER = "Software engineer",
  CEO = "CEO",
  MARKETING = "Marketing and social medal",
  ACCOUNTANT = "Accountant and HR",
}
