export enum PaymentType {
  Cash = "Cash",
  Electronic = "Electronic",
  Wallet = "Wallet",
}

export enum PaymentStatus {
  Abandoned = "abandoned",
  Failed = "failed",
  Ongoing = "ongoing",
  Pending = "pending",
  Processing = "processing",
  Queued = "queued",
  Reversed = "reversed",
  Success = "success",
}
export enum PaymentGateway {
  PayStack = "PayStack",
  Wallet = "Wallet",
  KudaBank = "KudaBank",
}

export enum PaymentPurpose {
  // Order = "Order",
  // Wallet = "Wallet",
  FundWallet = "FundWallet",
  OrderPayment = "OrderPayment",
  FundVirtualAccount = "FundVirtualAccount",
  NotDefined = "NotDefined",
}
export interface Payment {
  _id: string;
  orderId?: number;
  clientId: string;
  amount: number;
  date: Date;
  gatewayTransactionId: string;
  gatewayReference: string;
  status: PaymentStatus;
  paymentGateway: PaymentGateway;
  success: boolean;
  invoiceId: string;
  purpose: PaymentPurpose;
  paymentId: number;
  walletId?: number;
}
export enum PaymentStep {
  Initialize = "Initialize",
  StartUpdate = "StartUpdating",

  AddWalletTransactions = "AddWalletTransactions",
  GetWalletAccount = "GetWalletAccount",
  UpdatedWalletAccount = "UpdatedWalletAccount",

  GetVirtualAccount = "GetVirtualAccount",
  AddVirtualAccountTransactions = "AddVirtualAccountTransactions",
  UpdateVirtualAccount = "UpdateVirtualAccount",

  GetPayment = "GetPayment",
  UpdatePayment = "UpdatePayment",

  GetOrder = "GetOrder",
  UpdateOrder = "UpdateOrder",

  AllDDbUpdated = "AllDDbUpdated",
}

export interface PaymentUsableData {
  bin: number;
  last4: number;
  expireMonth: number;
  expireYear: number;
}
