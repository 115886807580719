import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";

const OrderNotFound: React.FC<{
  onSearch: (orderNumber: string, phone: string) => void;
  showPhoneNumber?: boolean;
  headerTitle?: string;
}> = ({
  onSearch,
  showPhoneNumber = false,
  headerTitle = "No Order Found",
}) => {
  const [orderNumber, setOrderNumber] = useState("");
  const [phone, setPhone] = useState("");

  const handleSearch = () => {
    if (orderNumber || phone) {
      onSearch(orderNumber, phone);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        gap: 2,
        p: 3,
      }}
    >
      <Typography variant="h6" gutterBottom>
        {headerTitle}
      </Typography>
      <Typography variant="body2">
        {`Please enter the Order Number ${showPhoneNumber ? "or phone number" : ""} to search again`}
      </Typography>
      <TextField
        label="Order Number"
        variant="outlined"
        value={orderNumber}
        onChange={(e) => setOrderNumber(e.target.value)}
        fullWidth
      />
      {showPhoneNumber && (
        <TextField
          label="Phone Number"
          variant="outlined"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          fullWidth
        />
      )}

      <Button
        variant="contained"
        color="primary"
        onClick={handleSearch}
        sx={{ mt: 2, width: "100%" }}
      >
        Search Order
      </Button>
    </Box>
  );
};

export default OrderNotFound;
