import React, { ChangeEvent, useState, FC, CSSProperties } from "react";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import {
	TextField,
	MenuItem,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Radio,
	RadioGroup,
	Tooltip,
	TextFieldProps,
} from "@mui/material";

interface InputFieldProps {
	label: string;
	value?: any;
	disabled?: boolean;
	required?: boolean;
	error?: boolean;
	helperText?: string;
	placeholder?: string;
	style?: CSSProperties;

	type?:
		| "email"
		| "password"
		| "text"
		| "number"
		| "search"
		| "tel"
		| "url"
		| "date"
		| "time";
	onChange?: (value: string) => void;
}

export const InputField: FC<InputFieldProps> = ({
	label,
	value,
	type = "text",
	error = false,
	disabled = false,
	helperText,
	required = false,
	placeholder,
	...props
}) => {
	const { onChange, style } = props;

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (onChange) {
			onChange(event.target.value);
		}
	};

	return (
		<TextField
			label={label}
			style={{ padding: "7px", ...style }}
			type={type}
			value={value}
			disabled={disabled}
			onChange={handleChange}
			variant="outlined"
			margin="normal"
			color="success"
			placeholder={placeholder}
			fullWidth
			required={required}
			autoComplete={type}
			error={error}
			helperText={helperText}
		/>
	);
};

interface SelectFieldProps {
	label: string;
	value: any | any[];
	options: { label: string; value: any }[];
	disabled?: boolean;
	required?: boolean;
	error?: boolean;
	helperText?: string;
	placeholder?: string;
	multiple?: boolean;
	onChange?: (value: any) => void;
}

export const SelectField: FC<SelectFieldProps> = ({
	label,
	value,
	options,
	error = false,
	disabled = false,
	helperText,
	required = false,
	multiple = false,
	placeholder,
	...props
}) => {
	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { onChange } = props;
		if (onChange) {
			onChange(event.target.value);
		}
	};

	return (
		<TextField
			select
			label={label}
			value={value}
			disabled={disabled}
			onChange={handleChange}
			variant="outlined"
			margin="normal"
			color="success"
			placeholder={placeholder}
			fullWidth
			required={required}
			error={error}
			helperText={helperText}
			slotProps={{
				select: {
					multiple: multiple,
				},
			}}
		>
			{options.map((option) => (
				<MenuItem key={option.value} value={option.value}>
					{option.label}
				</MenuItem>
			))}
		</TextField>
	);
};

export interface SelectorOptionProps {
	label: string;
	value: any;
	disabled?: boolean;
	showInfo?: boolean;
	infoContent?: string;
}
export interface RadioFieldProps {
	label: string;
	value?: any;
	id?: string;
	options: SelectorOptionProps[];
	disabled?: boolean;
	required?: boolean;
	error?: boolean;
	helperText?: string;
	onChange?: (value: any) => void;
}

export const RadioField: FC<RadioFieldProps> = ({
	label,
	value,
	options,
	error = false,
	disabled = false,
	helperText,
	required = false,
	id,
	...props
}) => {
	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { onChange } = props;
		if (onChange) {
			onChange(event.target.value);
		}
	};

	return (
		<FormControl
			component="fieldset"
			error={error}
			id={id}
			disabled={disabled}
			required={required}
		>
			<FormLabel component="legend">{label}</FormLabel>
			<RadioGroup value={value} onChange={handleChange}>
				{options.map((option) => (
					<FormControlLabel
						key={option.value}
						value={option.value}
						control={<Radio />}
						label={
							<CustomLabelWithInfo
								label={option.label}
								content={option.infoContent as any}
								showInfo={option.showInfo}
							/>
						}
						disabled={option.disabled}
					/>
				))}
			</RadioGroup>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</FormControl>
	);
};

export const CustomLabelWithInfo: FC<{
	label: string;
	infoContentStyle?: CSSProperties;
	content?: string;
	showInfo?: boolean;
}> = ({ content, infoContentStyle, showInfo, label }) => {
	const [open, setOpen] = useState(false);
	return (
		<div>
			<span>{label}</span>
			{showInfo && (
				<Tooltip
					open={open}
					onClose={() => setOpen(!open)}
					title={<InfoContent content={content} style={infoContentStyle} />}
					disableHoverListener
				>
					<IconButton onClick={() => setOpen(!open)}>
						<InfoIcon />
					</IconButton>
				</Tooltip>
			)}
		</div>
	);
};

const InfoContent: FC<{ style?: CSSProperties; content?: string }> = ({
	content,
	style,
}) => {
	return <p style={style}>{content}</p>;
};
