import React from "react";
import { Order, OrderStatus, OrderType, PaymentStatus } from "shared";
import { COLORS, NairaSign } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import useScreenTypeStore from "store/screenType";
import { ScreenType } from "interface";
import Loading from "ui/isLoading";
import { format } from "date-fns";
import { formatDate, handleTimePassed } from "utils/dates";

const DashboardOrderList: React.FC<{
  orders: Order[];

  rowClicked?: (orderNumber: number, orderType: OrderType) => void;
  loading?: boolean;
}> = ({ orders, rowClicked, loading = false }) => {
  const navigate = useNavigate();
  const { screenType } = useScreenTypeStore();

  const handleStatusColor = (status: OrderStatus) => {
    if (status === OrderStatus.Processing) return COLORS.YellowLight;
    if (status === OrderStatus.Paid || status === OrderStatus.Delivered)
      return COLORS.StatusGreen;
    return "red";
  };

  const handleSPaymentColor = (payment: PaymentStatus) => {
    if (
      payment === PaymentStatus.Failed ||
      payment === PaymentStatus.Abandoned
    ) {
      return "red";
    }

    if (payment === PaymentStatus.Success) return COLORS.StatusGreen;

    return COLORS.YellowLight;
  };

  const handleRowClicked = (orderNumber: number, orderType: OrderType) => {
    if (rowClicked) {
      rowClicked(orderNumber, orderType);
      return;
    }
    navigate(`/dashboard/order/detail/${orderNumber}`);
  };

  if (loading) return <Loading />;
  return (
    <div>
      <table
        style={{ width: "100%", backgroundColor: "transparent", gap: "10px" }}
        className="order-table"
      >
        <thead>
          <tr style={{ fontSize: 16 }}>
            <th className="poppins-regular ">Id</th>
            <th className="poppins-regular ">status </th>

            <th className="poppins-regular ">Date</th>
            <th className="poppins-regular ">Quantity</th>
            <th className="poppins-regular ">per kg</th>
            <th className="poppins-regular ">Amount</th>
            <th className="poppins-regular ">Payment</th>
            <th className="poppins-regular ">Pay type</th>
            <th className="poppins-regular ">delivered</th>
          </tr>
        </thead>
        <tbody style={{}}>
          {orders.map((order, index) => (
            <tr
              key={order.id}
              className={index % 2 === 0 ? "even-row" : "odd-row"}
              onClick={() =>
                handleRowClicked(order.orderNumber, order.orderType)
              }
            >
              <td>{"# " + order.orderNumber}</td>
              <td style={{ color: handleStatusColor(order.status) }}>
                {order.status}
              </td>

              <td>{formatDate(order.createdAt)}</td>

              <td>{order.quantityKg + " Kg"}</td>
              <td>{NairaSign + " " + order.pricePerKg}</td>
              <td>{NairaSign + " " + order.totalAmount}</td>
              <td style={{ color: handleSPaymentColor(order.paymentStatus) }}>
                {order.paymentStatus}
              </td>
              <td>{order.paymentType}</td>
              <td>{handleTimePassed(order.deliveredDate || "")}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DashboardOrderList;
