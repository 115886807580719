import { AxiosInstance } from "axios";
import { API_PATH } from "../apiPath";
import { Order, OrderWithClient } from "../interface";

export const findOrderByPickupCodeApi = async (
  axio: AxiosInstance,
  pickupCode: string
): Promise<Order> => {
  try {
    const data = await axio.get<Order>(
      API_PATH.pickup.findOrderByPickupCode(pickupCode).frontend
    );
    return data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
};

export const findOrderByQRCodeApi = async (
  axio: AxiosInstance,
  qrCodeValue: string
): Promise<Order> => {
  try {
    const data = await axio.get<Order>(
      API_PATH.pickup.findOrderByQRCode(qrCodeValue).frontend
    );
    return data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
};

export const requestPickupVerificationApi = async (
  axio: AxiosInstance,
  verificationData: any
): Promise<any> => {
  try {
    const data = await axio.post<any>(
      API_PATH.pickup.requestPickupVerification.frontend,
      verificationData
    );
    return data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
};
