import { isAxiosError, AxiosInstance } from "axios";
import {
  GasOrderForm,
  Order,
  Payment,
  PaymentInitData,
  PaymentUsableData,
  PaystackRetry,
  PaymentVerification,
} from "../interface";
import { API_PATH } from "../apiPath";

export const paymentInitApi = async (
  axiosInstance: AxiosInstance,
  form: GasOrderForm
) => {
  try {
    const _data = await axiosInstance.post<PaymentInitData>(
      API_PATH.payment.initiate.frontend,
      form
    );
    return _data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
 };;

export const retryPaymentPaystackApi = async (
  axiosInstance: AxiosInstance,
  form: PaystackRetry
) => {
  try {
    const _data = await axiosInstance.post<PaymentInitData>(
      API_PATH.payment.paystackRetryPayment.frontend,
      form
    );
    return _data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
 };

export const getPaymentByOrderApi = async (
  axiosInstance: AxiosInstance,
  orderId: string
) => {
  try {
    const _data = await axiosInstance.get<Payment>(`/payment/order/${orderId}`);
    return _data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
 };;

export const getPaymentByApi = async (axiosInstance: AxiosInstance) => {
  try {
    const _data = await axiosInstance.get<Payment>(`/payment/`);
    return _data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
 };

export const orderPaymentByWalletApi = async (
  axiosInstance: AxiosInstance,
  form: GasOrderForm
) => {
  try {
    const _data = await axiosInstance.post<Order>(`/payment/wallet`, form);
    return _data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
 };

export const getByPaymentNumberApi = async (
  axiosInstance: AxiosInstance,
  paymentNumber: string
) => {
  try {
    const _data = await axiosInstance.get<Payment>(
      API_PATH.payment.getByPaymentNumber(paymentNumber).frontend
    );
    return _data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
 };

export const verifyPaymentApi = async (
  axiosInstance: AxiosInstance,
  paymentNumber: string
) => {
  try {
    const _data = await axiosInstance.get<PaymentVerification>(
      API_PATH.payment.verifyPayment(paymentNumber).frontend
    );
    return _data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
 };

export const getPaymentUsableDataApi = async (axiosInstance: AxiosInstance) => {
  try {
    const _data = await axiosInstance.get<PaymentUsableData | null>(
      API_PATH.payment.getPaymentUsableData.frontend
    );
    return _data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
 };

export const savePaymentUsableDataApi = async (
  axiosInstance: AxiosInstance
) => {
  try {
    const _data = await axiosInstance.put(
      API_PATH.payment.savePaymentUsableData.frontend
    );
    return _data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
 };

export const discardPaymentUsableRedisDataApi = async (
  axiosInstance: AxiosInstance
) => {
  try {
    const _data = await axiosInstance.put(
      API_PATH.payment.discardPaymentUsableRedis.frontend
    );
    return _data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
 };
