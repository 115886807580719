import * as React from "react";
import { Link } from "react-router-dom";

export const CustomLink: React.FC<{
	text: string;
	to: string;
	color?: string;
	fontSize?: string;
}> = ({ text, to, color, fontSize }) => {
	return (
		<div>
			<Link
				className="link-text"
				style={{ color, fontSize }} // Apply fontSize
				to={to}
			>
				{text}
			</Link>
		</div>
	);
};
