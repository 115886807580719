import React, { FC, useEffect, useState } from "react";
import {
  OrderApiFilterParam,
  OrderStatus,
  PaymentStatus,
  Staff,
  StaffAccess,
  staffHasOnlyStaffAccess,
} from "shared";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { useClientStore } from "store";
import { getActiveStaffApi } from "shared";
import { AxiosApi } from "api";

const OrderFilterDrawer: FC<{
  filter: OrderApiFilterParam;
  setFilter: (filter: OrderApiFilterParam) => void;
  isDashboard?: boolean;
}> = ({ filter, setFilter, isDashboard = false }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [adminAccess, setAdminAccess] = useState(false);
  const [staffs, setStaffs] = useState<Staff[]>([]);
  const [clientPhone, setClientPhone] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const { staff } = useClientStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (staff) {
      setAdminAccess(!staffHasOnlyStaffAccess(staff.access));
    }
    if (adminAccess) {
      handleStaffs();
    }
  }, [staff, adminAccess]);

  const handleStaffs = () => {
    getActiveStaffApi(AxiosApi)
      .then((response) => {
        setStaffs(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const resetFilter = () => {
    setFilter({ ...Object(), orderStatus: "All" });
  };

  const filterDone = () => {
    setFilter({
      ...filter,
      clientMobileNumber: clientPhone,
      orderNumber: orderNumber as any,
    });
    setDrawerOpen(false);
  };

  return (
    <>
      <IconButton onClick={() => setDrawerOpen(true)}>
        <FilterListIcon />
      </IconButton>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <Box
          sx={{
            width: 300,
            p: 3,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">Filter Orders</Typography>
            <IconButton onClick={() => setDrawerOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Box>
              <Typography variant="caption">From Date</Typography>
              <TextField
                type="date"
                size="small"
                value={filter?.fromDate}
                onChange={(e) =>
                  setFilter({ ...filter, fromDate: e.target.value as any })
                }
                fullWidth
              />
            </Box>

            <Box>
              <Typography variant="caption">To Date</Typography>
              <TextField
                type="date"
                size="small"
                value={filter?.toDate}
                onChange={(e) =>
                  setFilter({ ...filter, toDate: e.target.value as any })
                }
                fullWidth
              />
            </Box>

            <Box>
              <Typography variant="caption">Order Status</Typography>
              <Select
                size="small"
                value={filter?.orderStatus}
                onChange={(e) =>
                  setFilter({ ...filter, orderStatus: e.target.value as any })
                }
                fullWidth
              >
                <MenuItem value="All">All Statuses</MenuItem>
                <MenuItem value={OrderStatus.NotPaid}>Not Paid</MenuItem>
                <MenuItem value={OrderStatus.Paid}>Paid</MenuItem>
                <MenuItem value={OrderStatus.Delivered}>Delivered</MenuItem>
                <MenuItem value={OrderStatus.Cancelled}>Cancelled</MenuItem>
                <MenuItem value={OrderStatus.Processing}>Processing</MenuItem>
              </Select>
            </Box>

            <Box>
              <Typography variant="caption">Payment Status</Typography>
              <Select
                size="small"
                value={filter?.paymentStatus}
                onChange={(e) =>
                  setFilter({ ...filter, paymentStatus: e.target.value as any })
                }
                fullWidth
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value={PaymentStatus.Success}>Success</MenuItem>
                <MenuItem value={PaymentStatus.Failed}>Failed</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </Box>
            <Box>
              <Typography variant="caption">Order number</Typography>
              <TextField
                type="number"
                size="small"
                value={filter?.orderNumber}
                onChange={(e) => setOrderNumber(e.target.value as any)}
                fullWidth
              />
            </Box>
            <Box>
              <Typography variant="caption">customer phone number</Typography>
              <TextField
                type="number"
                size="small"
                value={filter?.clientMobileNumber}
                onChange={(e) => setClientPhone(e.target.value as any)}
                fullWidth
              />
            </Box>

            {adminAccess && (
              <Box>
                <Typography variant="caption">By Staff</Typography>
                <Select
                  size="small"
                  value={filter?.staffId}
                  onChange={(e) =>
                    setFilter({ ...filter, staffId: e.target.value as any })
                  }
                  fullWidth
                >
                  <MenuItem value="All">All</MenuItem>
                  {staffs.map((staff, index) => (
                    <MenuItem key={index} value={staff.badgeNumber}>
                      {`${staff.firstname} | ${staff.badgeNumber}`}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            )}
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
            <Button variant="outlined" onClick={resetFilter} fullWidth>
              Reset
            </Button>
            <Button
              variant="contained"
              onClick={filterDone}
              fullWidth
              sx={{ ml: 2 }}
            >
              Done
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default OrderFilterDrawer;
