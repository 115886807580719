import { Button, Divider } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styles from "../../style.module.css";
import { CustomLink } from "../../ui/link";
import { useLocation, useNavigate } from "react-router-dom";
import { InputField } from "../../ui/inputs";
import { Body, ErrorMessage, Header } from "../../ui/typography";
import { loginApi } from "../../api/auth";
import { ColorEnum } from "../../utils/constants";
import { CustomError, ErrorType } from "shared";
import { useAuthStore } from "../../store/index";
import { handlePhoneNumberInput } from "utils/helper";

function Login() {
	const [error, setError] = useState("");
	const [mobileNumber, setMobileNumber] = useState("");
	const { setAuth, ...auth } = useAuthStore();
	const navigate = useNavigate();
	const location = useLocation();
	const numberRef = useRef("");

	useEffect(() => {
		// if (auth.isLogin) {
		//   navigate(location.state.from || "/");
		// }
	}, []);
	const handleNumberChange = (value: string) => {
		const sanitizedValue = value.replace(/\D/g, "");

		if (sanitizedValue.length > 11) {
			setMobileNumber(sanitizedValue.slice(0, 11));
		} else {
			setMobileNumber(sanitizedValue);
		}
	};

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const numInput = handlePhoneNumberInput(mobileNumber);
		if (!numInput.passCheck) {
			setError("Mobile number format is not correct");
			return;
		}

		loginApi({ mobileNumber: numInput.phone234 })
			.then((data) => {
				setAuth(data);
				navigate("/auth/verification/mobile", {
					state: {
						type: "mobile",
						mobileNumber,
						from: location.state?.from || "/",
					},
				});
			})
			.catch((error) => {
				if (error) {
					const err = error as CustomError;
					if (err.type === ErrorType.NoUserFound) {
						setError("Account with this detail does not exist,  Signup ");
						return;
					}
				}
				setError("Something went wrong, pls try again");
			});
	};

	return (
		<div
			style={{ height: "100%", margin: "auto", marginTop: "60px", width: 400 }}
			className="body-width"
		>
			<div style={{ textAlign: "center" }}>
				<Header text={"Login"} />
			</div>

			<form onSubmit={handleSubmit} className={styles.formContainer}>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Body text={"Login with your mobile number"} />
					<Divider style={{ maxWidth: "40%" }} />
				</div>
				<div style={{ display: "flex", padding: "auto" }}>
					{/* <span style={{ margin: "auto" }}>+234</span> */}
					<InputField
						value={mobileNumber}
						label="Mobile number"
						placeholder="070000000"
						type="tel"
						required={true}
						error={error !== ""}
						onChange={handleNumberChange}
					/>
				</div>

				<Button type="submit" fullWidth variant="contained">
					{" "}
					Continue
				</Button>

				<div
					style={{
						display: "flex",
						// justifyContent: "end",
						marginTop: "15px",
						gap: "5px",
					}}
				>
					<span>Dont have an account? </span>

					<CustomLink to="/Signup" text="Signup" color={ColorEnum.Blue} />
				</div>

				{error && <ErrorMessage text={error} />}
			</form>
		</div>
	);
}

export default Login;
