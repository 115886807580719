import { isAxiosError, AxiosInstance } from "axios";
import { Product } from "../interface";
import { API_PATH } from "../apiPath";

export const getProductsApi = async (axiosInstance: AxiosInstance) => {
  try {
    const _data = await axiosInstance.get<Product[]>(
      API_PATH.product.getProducts.frontend
    );
    return _data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
 };