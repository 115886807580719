import { isAxiosError, AxiosInstance } from "axios";
import { Order, OrderStatus, PickUpData, Sales } from "../interface";

export const ordersApi = async (
	axiosInstance: AxiosInstance,
	{
		status,
		fromDate,
		toDate,
	}: {
		status: OrderStatus;
		fromDate?: Date;
		toDate?: Date;
	}
) => {
	try {
		const _data = await axiosInstance.get<Order[]>(`admin/orders`, {
			params: {
				status,
				fromDate,
				toDate,
			},
		});
		return _data.data;
	} catch (error: any) {
		throw error.response?.data || error.message;
	  }
	  };

export const pickUpApi = async (axiosInstance: AxiosInstance, orderId: number) => {
	try {
		const _data = await axiosInstance.get<PickUpData>(`admin/pickup/${orderId}`);
		return _data.data;
	} catch (error: any) {
		throw error.response?.data || error.message;
	  }
	  };

export const mySalesApi = async (
	axiosInstance: AxiosInstance,
	{
		fromDate = new Date(),
		toDate = new Date(),
	}: {
		fromDate?: Date;
		toDate?: Date;
	}
) => {
	try {
		const _data = await axiosInstance.get<Sales>(`admin/sales/staff`, {
			params: { fromDate, toDate },
		});
	    return _data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
};

export const staffSalesApi = async (axiosInstance: AxiosInstance, staffNumber: number) => {
	try {
		const _data = await axiosInstance.get<PickUpData>(
			`admin/sales/staff/${staffNumber}`
		);
		return _data.data;
} catch (error: any) {
  throw error.response?.data || error.message;
}
};
