import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Navigation } from "@toolpad/core/AppProvider";

const NAVIGATION: Navigation = [
	{
		kind: "header",
		title: "Dashboard",
	},
	{
		segment: "dashboard",
		title: "Dashboard",
		icon: <DashboardIcon />,
	},
	{
		segment: "dashboard/order",
		title: "Order",
		icon: <ShoppingCartIcon />,
		children: [
			{
				segment: "list",
				title: "Orders",
				icon: <ShoppingCartIcon />,
			},
			{
				segment: "detail",
				title: "Detail",
				pattern: "detail{/:orderId}*",

				icon: <ShoppingCartIcon />,
			},
		],
	},
	{
		segment: "dashboard/sales",
		title: "Sales",
		icon: <ShoppingCartIcon />,
	},
	{
		segment: "dashboard/update",
		title: "Update data",
		icon: <ShoppingCartIcon />,
	},
	{
		segment: "dashboard/staff/create",
		title: "Add staff",
		icon: <ShoppingCartIcon />,
	},
	{
		segment: "dashboard/pickup",
		title: "Pick up",
		icon: <ShoppingCartIcon />,
	},
	{
		segment: "dashboard/shift",
		title: "Shift",
		icon: <ShoppingCartIcon />,
	},
];
const SubOrder: Navigation = [
	{
		segment: "order",
		title: "Order detail",
		pattern: "order{/:orderId}*",

		icon: <ShoppingCartIcon />,
	},
];

export const DASHBOARD_NAVIGATION = {
	main: NAVIGATION,
	SubOrders: SubOrder,
};
