export enum PickupConfirmationAction {
  Approve = "Approve ",
  Rejected = "Rejected",
  ClientOffLine = "ClientOffLine",
  Pending = "Pending",
}
export interface OrderPickup {
  pickupId: string;
  clientId: string;
  orderId: string;
  pickupLocation: string;
  deliveryLocation: string;
  pickupTime: Date;
  deliveryTime?: Date;
  status: PickupStatus;
}

export enum PickupStatus {
  Pending = "Pending",
  InProgress = "InProgress",
  Completed = "Completed",
  Cancelled = "Cancelled",
  ClientReject = "ClientReject",
  Approved = "Approved",
}

export enum PickupEligibilityStatus {
  Eligible = "Eligible",
  AlreadyPickedUp = "AlreadyPickedUp",
  NotPaid = "NotPaid",
  DisputeExists = "DisputeExists",
  Error = "Error"
}