import { getAppData } from "api/app";
import { getOrderByClientApi } from "api/order";
import { isToday } from "date-fns";
import { ScreenType } from "interface";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Order, OrderStatus, PaymentStatus, OrderApiFilterParam } from "shared";
import { useClientStore } from "store";
import { useAppDataStore } from "store/appData";
import useScreenTypeStore from "store/screenType";
import { Btn } from "ui/button";
import OrderListTable from "ui/order/OrderList";
import { BodyWithClass } from "ui/typography";
import { NairaSign } from "utils/constants";

export const LoginHome = () => {
	const { appData, loadedAt, setData } = useAppDataStore();
	const { client } = useClientStore();
	const { screenType } = useScreenTypeStore();
	const navigate = useNavigate();
	const [pendingOrders, setPendingOrders] = useState<Order[]>([]);
	const [pickupOrders, setPickupOrders] = useState<Order[]>([]);

	useEffect(() => {
		if (!appData) {
			handleAppData();
			return;
		}
		if (loadedAt && !isToday(loadedAt)) {
			handleAppData();
		}
		if (client) {
			const pickup = true;
			orders(
				{
					paymentSuccess: true,
					orderStatus: OrderStatus.Paid,
					paymentStatus: PaymentStatus.Success,
					skip: 0,
					take: 2,
				},
				pickup
			);
			orders({
				skip: 1,
				take: 3,
				// notEqualToOrderStatus: OrderStatus.Paid,
			});
		}
	}, [client]);

	const handleAppData = () => {
		getAppData()
			.then((data) => {
				setData(data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const orders = (filter: OrderApiFilterParam, pickupItem: boolean = false) => {
		getOrderByClientApi(filter)
			.then((orders) => {
				if (pickupItem) {
					setPickupOrders(orders);
				} else {
					setPendingOrders(orders);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<div
			style={{
				width: "600px",
				maxWidth: "calc(100% - 40px)",
				margin: "auto",
				overflow: "auto",
				padding: "15px",
				marginTop: screenType === ScreenType.Mobile ? "20px" : "100px",
			}}
		>
			<div className="gas-price-marquee" style={{ marginBottom: "20px" }}>
				<div>
					<BodyWithClass
						text={`Current Gas Price: ${NairaSign + appData?.currentPrice}`}
						className={"poppins-semibold"}
						style={{ fontSize: "18px" }}
					/>
				</div>
			</div>
			<div style={{ maxHeight: 400 }}>
				<BodyWithClass
					text={"Pickup Orders"}
					className={"poppins-semibold"}
					style={{ fontSize: "18px" }}
				/>
				<div>
					<OrderListTable
						orders={pickupOrders}
						emptyMessage="No oder to pick up, place an order!"
					/>
				</div>
			</div>
			<div style={{ marginTop: "30px" }}>
				<Btn
					text={" New Order! "}
					onClick={() => navigate("/order/new")}
					style={{ width: "150px" }}
				/>
			</div>

			<div style={{ marginTop: "20px" }}>
				<BodyWithClass
					text={"Recent orders"}
					className={"poppins-semibold"}
					style={{ fontSize: "18px" }}
				/>
				<div style={{ maxHeight: 400, overflow: "auto", width: "100%" }}>
					<OrderListTable
						orders={pendingOrders}
						emptyMessage="No recent orders!"
					/>
				</div>
			</div>
		</div>
	);
};
