import { PaymentPurpose } from "./payment";

export enum WalletTransactionStatus {
  Pending = "pending",
  Completed = "completed",
  Failed = "failed",
  Success = "success",
}

export enum WalletFundSource {
  BankTransfer = "BankTransfer",
  Card = "Card",
  VirtualAccount = "virtualAccount",
  Cash = "Cash",
  CardOrBankTransfer = "CardOrBankTransfer",
}

export enum WalletTransactionType {
  Debit = "Debit",
  Credit = "Credit",
}

export interface WalletTransactionFrom {
  shortName: string;
  name: string;
  bankName?: string;
  accountNumber?: string;
  logo?: string;
}
export interface WalletTransaction {
  id: string;
  from: WalletTransactionFrom;
  to: WalletTransactionFrom;
  amount: number;
  walletNumber: number;
  createdAt: Date;
  status: WalletTransactionStatus;
  type: WalletTransactionType;
  source?: WalletFundSource;
  purpose: PaymentPurpose;
  displayName: string;
  displayLogo: string;
}
export interface Wallet {
  // id: string;
  //_id: string;
  // clientId: String;
  // lastAdded: Date;
  balance: number;
  // lastUsed: Date;
  transactions: WalletTransaction[];
  walletNumber: number;
  // createdAt: Date;
  pin?: number;
  virtualAccount?: {
    accountName: string;
    accountNumber: string;
    bankName: string;
    bankNameSlug: string;
  };
}
