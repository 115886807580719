import React from "react";
import { CheckCircle } from "lucide-react";
import { Btn } from "ui/button";

interface Action {
	text: string;
	onClick: () => void;
	color?: string;
	bgColor?: string;
	style?: React.CSSProperties;
}

interface SuccessPageProps {
	title: string;
	description: string;
	subDescription?: string;
	actions: Action[];
}

const SuccessPage: React.FC<SuccessPageProps> = ({
	title = "Success!",
	description = "Your action was successful.",
	subDescription,
	actions,
}) => {
	const iconColor = "green";

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				backgroundColor: "#f9fafb",
				padding: "1rem",
			}}
		>
			<div
				style={{
					borderRadius: "50%",
					padding: "1rem",
					// backgroundColor: `${iconColor}`,
				}}
			>
				<CheckCircle size={60} style={{ color: iconColor }} />
			</div>

			<h1
				style={{
					fontSize: "1.25rem",
					fontWeight: "bold",
					marginTop: "1rem",
					textAlign: "center",
					color: "#1f2937",
				}}
			>
				{title}
			</h1>

			<p style={{ color: "#4b5563", textAlign: "center", marginTop: "0.5rem" }}>
				{description}
			</p>

			{subDescription && (
				<p
					style={{
						color: "#6b7280",
						textAlign: "center",
						marginTop: "0.25rem",
					}}
				>
					{subDescription}
				</p>
			)}

			<div
				style={{
					display: "flex",
					flexDirection: "column",
					gap: "0.5rem",
					marginTop: "1.5rem",
					width: "100%",
					maxWidth: "16rem",
				}}
			>
				{actions.map((action, index) => (
					<Btn
						key={index}
						onClick={action.onClick}
						text={action.text}
						style={{
							color: action.color,
							backgroundColor: action.bgColor,
							...action.style,
						}}
					/>
				))}
			</div>
		</div>
	);
};

export default SuccessPage;
