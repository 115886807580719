import {  Wallet} from "shared";
import { create } from "zustand";

interface WalletStore {
  wallet: Wallet | null;
  setWallet: (form: Wallet| null) => void;
}

export const useWalletStore = create<WalletStore >()((set) => ({
    wallet: null,
    setWallet: (wallet: Wallet| null) => set(() => ({ wallet })),
}));
