import React from "react";
import {
	Box,
	Button,
	Typography,
	Container,
	Grid2,
	Paper,
} from "@mui/material";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ScheduleIcon from "@mui/icons-material/Schedule";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

const RiderSignupLanding = () => {
	return (
		<Container maxWidth="lg" sx={{ textAlign: "center", py: 5 }}>
			{/* Hero Section */}
			<Box sx={{ my: 5 }}>
				<Typography variant="h3" fontWeight="bold" gutterBottom>
					Earn Money on Your Schedule
				</Typography>
				<Typography variant="h6" color="textSecondary" gutterBottom>
					Become a rider and start earning today. Enjoy flexibility and great
					support.
				</Typography>
				<Button
					variant="contained"
					color="primary"
					size="large"
					href="/rider/auth"
				>
					Sign Up to Ride
				</Button>
			</Box>

			{/* Benefits Section */}
			<Grid2 container spacing={4} sx={{ mt: 4 }}>
				<Grid2>
					<Paper elevation={3} sx={{ p: 3, textAlign: "center" }}>
						<MonetizationOnIcon color="primary" fontSize="large" />
						<Typography variant="h6" fontWeight="bold" mt={2}>
							Earn More
						</Typography>
						<Typography variant="body2" color="textSecondary">
							Get paid weekly and keep more of what you earn.
						</Typography>
					</Paper>
				</Grid2>
				<Grid2>
					<Paper elevation={3} sx={{ p: 3, textAlign: "center" }}>
						<ScheduleIcon color="primary" fontSize="large" />
						<Typography variant="h6" fontWeight="bold" mt={2}>
							Flexible Schedule
						</Typography>
						<Typography variant="body2" color="textSecondary">
							Work when you want. Choose your own hours.
						</Typography>
					</Paper>
				</Grid2>
				<Grid2>
					<Paper elevation={3} sx={{ p: 3, textAlign: "center" }}>
						<DirectionsBikeIcon color="primary" fontSize="large" />
						<Typography variant="h6" fontWeight="bold" mt={2}>
							Ride Your Way
						</Typography>
						<Typography variant="body2" color="textSecondary">
							Use your bike, scooter, or car to make deliveries.
						</Typography>
					</Paper>
				</Grid2>
				<Grid2>
					<Paper elevation={3} sx={{ p: 3, textAlign: "center" }}>
						<SupportAgentIcon color="primary" fontSize="large" />
						<Typography variant="h6" fontWeight="bold" mt={2}>
							24/7 Support
						</Typography>
						<Typography variant="body2" color="textSecondary">
							We’re here to help whenever you need assistance.
						</Typography>
					</Paper>
				</Grid2>
			</Grid2>

			<Box sx={{ my: 5 }}>
				<Typography variant="h5" fontWeight="bold" gutterBottom>
					Ready to Start Earning?
				</Typography>
				<Typography variant="body1" color="textSecondary" gutterBottom>
					Sign up today and join thousands of riders who are making great
					earnings on their terms.
				</Typography>
				<Button variant="contained" color="primary" size="large">
					Get Started
				</Button>
			</Box>
		</Container>
	);
};

export default RiderSignupLanding;
