import React, { FC, useState } from "react";
import { Box, TextField, Alert } from "@mui/material";
import QRScanner from "ui/qrCode/QRCodeScanner";
import { PickupType } from "interface";
import { Btn } from "ui/button";
import { useNavigate } from "react-router-dom";

const FindPickupOrder: FC<{
  searchOrder: (code: string, type: PickupType) => void;
  codeMatch: boolean;
  verificationMessage: string;
  loading?: boolean;
}> = ({
  searchOrder,
  codeMatch = false,
  verificationMessage,
  loading = false,
}) => {
  const [code, setCode] = useState("");
  const [pickupType, setPickup] = useState(PickupType.PickupCode);
  const handleFindOrder = () => {
    searchOrder(code, pickupType);
  };
  return (
    <Box p={3}>
      <div
        style={{
          gap: 30,
          display: "flex",
          justifyContent: "center",
          marginBottom: "50px",
        }}
      >
        <Btn text={"Scan code"} onClick={() => setPickup(PickupType.QrCode)} />
        <Btn
          text={"Pickup Code"}
          onClick={() => setPickup(PickupType.PickupCode)}
        />
      </div>
      <div style={{ marginBottom: "50px" }}>
        {pickupType === PickupType.QrCode && (
          <div style={{}}>
            <QRScanner setReadData={(value) => setCode(value)} />
          </div>
        )}
        {pickupType === PickupType.PickupCode && (
          <div>
            <TextField
              label="Pickup Code"
              variant="outlined"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              fullWidth
            />
          </div>
        )}
        {pickupType === PickupType.OrderNumber && (
          <div>
            <TextField
              label="Order number"
              variant="outlined"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              fullWidth
            />
          </div>
        )}
      </div>
      {!codeMatch && verificationMessage && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {verificationMessage}
        </Alert>
      )}
      <div>
        <Btn
          text={"Find Order "}
          onClick={handleFindOrder}
          isLoading={loading}
          isDisabled={code.length === 0}
        />
      </div>
    </Box>
  );
};

export default FindPickupOrder;
