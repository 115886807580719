import { AxiosInstance } from "axios";
import { AppData, StaffForm } from "../interface";
import { API_PATH } from "../apiPath";

export const addEmployeeApi = async (axiosInstance: AxiosInstance, form: StaffForm): Promise<any> => {
  try {
    const _data = await axiosInstance.post(API_PATH.app.getAppData.frontend, form);
    return _data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
 };

export const getAppData = async (axiosInstance: AxiosInstance) => {
  try {
    const _data = await axiosInstance.get<AppData>(API_PATH.app.getAppData.frontend);
    return _data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
 };