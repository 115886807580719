import { formatDistanceToNow } from "date-fns";
import { format } from "date-fns";

export function handleTimePassed(dateString: string | Date): string {
  if (!dateString) {
    return "";
  }
  const date = new Date(dateString);
  return formatDistanceToNow(date, { addSuffix: true });
}

export function formatDate(
  dateString: string | Date,
  dateFormat: string = "dd.MM.yyyy"
): string {
  if (!dateString) {
    return "";
  }
  const date = new Date(dateString);
  return format(date, dateFormat);
}

// Example usage of formatDate function with possible format strings
// formatDate('2023-10-05T14:48:00.000Z', 'yyyy-MM-dd'); // Output: 2023-10-05
// formatDate('2023-10-05T14:48:00.000Z', 'MM/dd/yyyy'); // Output: 10/05/2023
// formatDate('2023-10-05T14:48:00.000Z', 'dd MMM yyyy'); // Output: 05 Oct 2023
// formatDate('2023-10-05T14:48:00.000Z', 'EEEE, MMMM do, yyyy'); // Output: Thursday, October 5th, 2023
