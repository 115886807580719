import { CustomError } from "../error";
import { PickupEligibilityStatus, OrderStatus, OrderPickUpDispute, Order, PaymentStatus} from "../interface";

export function verifyPickupEligibility( order: Order): PickupEligibilityStatus {
    if (!order) {
        return PickupEligibilityStatus.Error;
    }

    if (order.isDelivered) {
        return PickupEligibilityStatus.AlreadyPickedUp;
    }

    if (order.paymentStatus !== PaymentStatus.Success) {
        return PickupEligibilityStatus.NotPaid;
    }
    if (order.status === OrderStatus.Delivered) {
        return PickupEligibilityStatus.AlreadyPickedUp;
    }

    if (order.pickupDispute !== OrderPickUpDispute.None) {
        return PickupEligibilityStatus.DisputeExists;
    }

    return PickupEligibilityStatus.Eligible;
}
