import { DeliveryOption } from "./delivery";
import { PaymentStatus, PaymentType } from "./payment";
import { Product } from "./product";
import { Staff } from "./staff";

export * from "./staff";
export * from "./form";
export * from "./supply";
export * from "./product";
export * from "./delivery";
export * from "./wallet";
export * from "./payment";
export * from "./utils";
export * from "./order";
export * from "./socketio";
export * from "./pickup";
export * from "./riderApp";


export enum Platform {
  Android = "android",
  IOS = "ios",
  Web = "web",
}
export interface SignupForm {
  // firstname: string;
  // lastname: string;
  name: string;
  mobileNumber: string;
  // platform: Platform;
  email?: string;
}
export interface Client {
  name?: string;
  firstname?: string;
  lastname?: string;
  username: string;
  mobileNumber: string;
  _id: string;
  deviceId: string;
  id: string;
  email?: string;
  isStaff: boolean;
  staff?: Staff;
  paystackCustomerCode?: string;
  cylinder?: Product;
}

export enum OptVerificationType {
  SMS = "SMS",
  Email = "Email",
}

export interface Device {
  id: string;
  name: string;
  model: string;
  builtId: string;
}
export interface CustomRequestHeader {
  device: Device;
  token: string;
}
export interface LoginForm {
  mobileNumber: string;
  verificationType?: OptVerificationType;
  email?: string;
}

export interface LoginResponse {
  token: string;
  isVerified: boolean;
  client: Client;
}

export interface OTPConfirmation {
  optCode: number;
  clientId: string;
  //Todo: we dont need this any
  // Platform?: Platform;
  // device?: Device;
  // platform?: Platform;
}

export interface FileUploadResponse {
  fileUrl: string;
  fileName: string;
  fileId: string;
}

export interface OptResponseDta {
  expiredAt: Date;
}
export interface Auth {
  clientId?: string;
  isVerified: boolean;
  isLogin: boolean;
  lastLogin?: Date;
  token?: string;
  mobileNumber?: string;
  optData?: OptResponseDta;
}

export interface DataInterface {
  client: Client;
  // staff?: Staff;
  auth: Auth;
}

export interface AppData {
  message: string;
  currentPrice: number;
  previousPrice: number;
  remainingQuantity: number;
  lastUpdated: Date;
  otuokeDeliveryCost: number;
  isOpen: boolean;
}

export interface PrivacySettingsType {
  name: string;
  accepted: boolean;
  acceptedAt: Date;
}
