import React, { useState, useEffect } from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Typography,
	CircularProgress,
	Snackbar,
	Alert,
} from "@mui/material";
import {
	ClientPickupConfirmationData,
	ClientPickupConfirmationResponse,
	emitClientSocketEvent,
	onClientSocketEvent,
	PickupConfirmationAction,
	startClientSocket,
} from "shared";
import { SOCKET_EVENTS } from "shared";
import { useClientStore } from "store";
import SuccessPage from "ui/sucess/Sucess";

const PickupConfirmationModal = () => {
	const [open, setOpen] = useState(false);
	const [orderConfirmed, setOrderConfirmed] = useState(false);
	const [loading, setLoading] = useState(false);
	const [orderNumber, setOrderNumber] = useState("");
	const [eventData, setEventData] =
		useState<ClientPickupConfirmationData | null>(null);

	const { client } = useClientStore();
	const [snackbar, setSnackbar] = useState({
		open: false,
		message: "",
		severity: "success",
	});

	useEffect(() => {
		const socket = startClientSocket();
		onClientSocketEvent(SOCKET_EVENTS.pickup.pingClient, () => {
			console.log("Ping received from server");
			emitClientSocketEvent(SOCKET_EVENTS.pickup.pingClientResponse, {});
			if (!client) {
				console.log("no client");
				return;
			}
			socket.emit(SOCKET_EVENTS.pickup.pingClientResponse, {
				clientId: client.id,
			});
		});

		onClientSocketEvent(
			SOCKET_EVENTS.pickup.requestPickupConfirmationToClient,
			(data: ClientPickupConfirmationData) => {
				console.log("Request received from server", data);
				setOrderNumber(data.orderNumber as any);
				setEventData(data);
				setOpen(true);
			}
		);
		onClientSocketEvent(SOCKET_EVENTS.pickup.staffConfirm, () => {
			setOrderConfirmed(true);
		});

		return () => {
			socket.close();
		};
	}, [client]);

	const handleConfirmPickup = () => {
		setLoading(true);
		if (!eventData) {
			//Todo : handle error
			return;
		}
		if (!client) return;

		const data: ClientPickupConfirmationResponse = {
			approvePickup: true,
			clientId: client?.id,
			message: "pickup approved",
			staffId: eventData.staffId,
			clientAction: PickupConfirmationAction.Approve,
			orderId: eventData.orderId,
		};
		emitClientSocketEvent(
			SOCKET_EVENTS.pickup.clientPickupConfirmationResponse,
			data
		);
	};

	const handleCancel = () => {
		const data: ClientPickupConfirmationResponse = {
			approvePickup: false,
			clientId: "",
			message: "",
			staffId: "",
			clientAction: PickupConfirmationAction.Rejected,
			orderId: "",
		};
		emitClientSocketEvent(
			SOCKET_EVENTS.pickup.clientPickupConfirmationResponse,
			data
		);
		setOpen(false);
		setSnackbar({
			open: true,
			message: "Pickup request canceled.",
			severity: "info",
		});
	};

	const handleClose = () => {
		setOpen(false);
	};
	return (
		<>
			<Dialog open={open} onClose={handleCancel}>
				{orderConfirmed ? (
					<div>
						<SuccessPage
							actions={[{ text: "Close now", onClick: handleClose }]}
							title={"Order Pickup  confirm"}
							description={
								" Your Order pickup has been confirm and is been processed"
							}
							subDescription="Thank your for choosing us !"
						/>
					</div>
				) : (
					<div>
						<DialogTitle>Confirm Your Pickup</DialogTitle>
						<DialogContent>
							<Typography variant="body1">
								A staff member is requesting confirmation for your order pickup.
								Please confirm to proceed.
							</Typography>
						</DialogContent>
						<DialogActions>
							<Button
								onClick={handleCancel}
								color="secondary"
								disabled={loading}
							>
								Cancel
							</Button>
							<Button
								onClick={handleConfirmPickup}
								color="primary"
								variant="contained"
								disabled={loading}
							>
								{loading ? (
									<CircularProgress size={24} color="inherit" />
								) : (
									"Confirm Pickup"
								)}
							</Button>
						</DialogActions>
					</div>
				)}
			</Dialog>

			<Snackbar
				open={snackbar.open}
				autoHideDuration={3000}
				onClose={() => setSnackbar({ ...snackbar, open: false })}
			>
				<Alert severity={snackbar.severity as "success" | "error" | "info"}>
					{snackbar.message}
				</Alert>
			</Snackbar>
		</>
	);
};

export default PickupConfirmationModal;
