import { createStaffApi } from "api/staff";
import React, { useState } from "react";
import { StaffAccess, StaffAccessKeys, StaffForm } from "shared";
import { InputField, SelectField } from "ui/inputs";
import { BodyHeader } from "ui/typography";

const NewStaff = () => {
  const [form, setForm] = useState<StaffForm>({
    firstname: "",
    lastname: "",
    mobileNumber: "",
    email: "",
    access: [StaffAccess.Staff],
  });

  const [errorType, setErrorType] = useState<any>({});

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const errors: any = {};

    if (!form.firstname) errors.firstName = "First name is required";
    if (!form.lastname) errors.lastName = "Last name is required";
    if (!form.mobileNumber) errors.mobileNumber = "Phone number is required";

    setErrorType(errors);

    if (Object.keys(errors).length !== 0) {
      console.log("Form submitted:", form);
      return;
      // Add submission logic here (e.g., API call)
    }

    createStaffApi(form)
      .then((resposne) => {
        console.log(resposne);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <BodyHeader text={"Add New Staff"} />
      <div>
        <InputField
          label="First Name"
          placeholder="Enter first name"
          value={form.firstname}
          onChange={(value) => setForm({ ...form, firstname: value })}
          error={errorType.firstname}
          required
          type={"text"}
        />
        <InputField
          label="Last Name"
          placeholder="Enter last name"
          value={form.lastname}
          onChange={(value) => setForm({ ...form, lastname: value })}
          error={errorType.lastname}
          type={"text"}
        />
        <InputField
          label="Phone Number"
          placeholder="Enter phone number"
          value={form.mobileNumber}
          onChange={(value) => setForm({ ...form, mobileNumber: value })}
          error={errorType.mobileNumber}
          required
          type={"tel"}
        />
        <SelectField
          label={"Access"}
          onChange={(value) => setForm({ ...form, access: value })}
          value={form.access}
          multiple={true}
          options={StaffAccessKeys.map((item) => ({
            label: item.key,
            value: item.value,
          }))}
        />
        <InputField
          label="Email"
          placeholder="Enter email"
          value={form.email}
          onChange={(value) => setForm({ ...form, email: value })}
          error={errorType.email}
          type={"text"}
        />
        <div style={{ marginTop: "16px" }}></div>
        <button type="submit">Submit</button>
      </div>
    </form>
  );
};

export default NewStaff;
