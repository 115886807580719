const sales = "/sales";

type ApiPath = {
    frontend: string;
    backend: string;
};

export const SalesPath = {
    sales,
};

const api_prefix = "api";

const handlePath = ({ path }: { path: string }) => {
    const apiPath: ApiPath = {
        frontend: `${api_prefix}${sales}/${path}`,
        backend: `/${path}`,
    };
    return apiPath;
};

export const Sales_API_PATH = {
    getSales: handlePath({ path: "" }),
    getSalesByStaff:( id: string) =>  handlePath({ path: `staff/${id}` }),
};
