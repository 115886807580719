import { AxiosApi } from "api";
import { ScreenType } from "interface";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getOrderWithClientApi, OrderWithClient } from "shared";
import useScreenTypeStore from "store/screenType";
import DashboardOrderDetail from "ui/dashboard/OrderDetail";
import OrderNotFound from "ui/dashboard/OrderNotFound";
import Loading from "ui/isLoading";
import { BodyWithClass } from "ui/typography";

const DashboardOrderDetailPage = () => {
	const { orderNumber: id } = useParams();
	const [order, setOrder] = useState<OrderWithClient>();
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	const { screenType } = useScreenTypeStore();

	useEffect(() => {
		if (!id) {
			setLoading(false);
			return;
		}

		getOrderWithClientApi(AxiosApi, id as any)
			.then((response) => {
				setOrder(response);
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [id]);

	const findOrder = (orderNumber?: string, clientPhoneNumber?: string) => {
		if (orderNumber) {
			navigate(`/dashboard/order/detail/${orderNumber}`);
		}
	};
	if (loading) return <Loading />;
	return (
		<div
			style={{
				padding: "15px",
				margin: "auto",
			}}
		>
			{order ? (
				<div
					style={{
						width: "500px",
						maxWidth: "100%",
						margin: "auto",
						overflow: "auto",
						height: "100%",
					}}
				>
					<div style={{ marginBottom: "20px" }}>
						<BodyWithClass text={"Order summary"} className={"poppins-bold "} />
					</div>
					<div>
						<DashboardOrderDetail order={order.order} client={order.client} />
					</div>
				</div>
			) : (
				<div>
					<div>
						<BodyWithClass
							text={`Order with this is # ${id} was not found!`}
							className={""}
						/>
					</div>
					<OrderNotFound onSearch={findOrder} />
				</div>
			)}
		</div>
	);
};

export default DashboardOrderDetailPage;
