// create store for shift

import { Shift } from 'shared';
import { create } from 'zustand';

interface ShiftStore {
    shift: Shift | null;
    setShift: (shift: Shift) => void;
    clearShift: () => void;
}


export const useShiftStore = create<ShiftStore>()((set) => ({
    shift: null,
    setShift: (shift: Shift) => set({ shift }),
    clearShift: () => set({ shift: null })
}));
