export const COLORS = {
    // Base Colors
    primary: "#FADCD2", // Soft pinkish-beige (base)
    primaryLight: "#FFE9E2", // Lighter shade of primary
    primaryDark: "#E6BDB2", // Darker shade of primary
  
    secondary: "#0059C9", // Deep blue
    secondaryLight: "#4A87F5", // Lighter shade of secondary
    secondaryDark: "#003A8C", // Darker shade of secondary
  
    // Supporting Colors
    accent: "#FFAD94", // Accent orange-pink
    accentLight: "#FFD2C7", // Lighter accent
    accentDark: "#E68A6C", // Darker accent
  
    // Neutral Colors
    white: "#FFFFFF", // Pure white
    black: "#333333", // Dark gray-black
    gray: "#9E9E9E", // Neutral gray
    lightGray: "#F5F5F5", // Background gray
    darkGray: "#666666", // Text gray
  
    // Status Colors
    success: "#4CAF50", // Green for success
    successLight: "#81C784", // Lighter green
    successDark: "#388E3C", // Darker green
  
    warning: "#FFC107", // Yellow for warnings
    warningLight: "#FFD54F", // Lighter yellow
    warningDark: "#FFA000", // Darker yellow
  
    error: "#F44336", // Red for errors
    errorLight: "#E57373", // Lighter red
    errorDark: "#D32F2F", // Darker red
  
    info: "#2196F3", // Blue for info
    infoLight: "#64B5F6", // Lighter blue
    infoDark: "#1976D2", // Darker blue
  
    // Backgrounds
    background: {
      default: "#F5F5F5", // Light neutral background
      paper: "#FFFFFF", // White for cards and panels
      highlight: "#FFF5F0", // Highlight background derived from primary
    },
  
    // Text Colors
    text: {
      primary: "#333333", // Main text
      secondary: "#666666", // Subtle text
      muted: "#9E9E9E", // Muted or disabled text
      link: "#0059C9", // Link color (based on secondary)
      contrast: "#FFFFFF", // High contrast text
    },
  };
  
  // export const COLORS = {
  //   primary: { base: "#FADCD2", light: "#FFE9E2", dark: "#E6BDB2" },
  //   secondary: { base: "#0059C9", light: "#4A87F5", dark: "#003A8C" },
  //   accent: { base: "#FFAD94", light: "#FFD2C7", dark: "#E68A6C" },
  //   neutrals: { white: "#FFFFFF", black: "#333333", gray: "#9E9E9E", lightGray: "#F5F5F5", darkGray: "#666666" },
  //   status: { success: "#4CAF50", warning: "#FFC107", error: "#F44336", info: "#2196F3" },
  // };