import { Client, Staff } from "shared";
import { create } from "zustand";

interface ClientStore {
  client: Client | null;
  staff: Staff | null;
  setClient: (user: Client | null) => void;
  logonStore: () => void;
}

export const useClientStore = create<ClientStore>()((set) => ({
  client: null,
  staff: null,
  setClient: (client: Client | null) =>
    set(() => ({ client, staff: client?.staff || null })),
  logonStore: () => set(() => ({ client: null })),
}));
