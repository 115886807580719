import React, { FC, useEffect, useState } from "react";
import {
  OrderApiFilterParam,
  OrderStatus,
  PaymentStatus,
  Staff,
  staffHasOnlyStaffAccess,
} from "shared";
import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useClientStore } from "store";
import { getActiveStaffApi } from "shared";
import { AxiosApi } from "api";
import { Btn } from "ui/button";

const OrderFilter: FC<{
  filter: OrderApiFilterParam;
  setFilter: (filter: OrderApiFilterParam) => void;
  isDashboard?: boolean;
}> = ({ filter, setFilter }) => {
  const resetFilter = () => setFilter({ orderStatus: "All" });
  const [adminAccess, setAdminAccess] = useState(false);
  const [staffs, setStaffs] = useState<Staff[]>([]);
  const { staff } = useClientStore();
  const navigate = useNavigate();

  const isSmallScreen = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    if (staff) setAdminAccess(!staffHasOnlyStaffAccess(staff.access));
    if (adminAccess) handleStaffs();
  }, [staff, adminAccess]);

  const handleStaffs = () => {
    getActiveStaffApi(AxiosApi)
      .then((response) => setStaffs(response))
      .catch(console.error);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isSmallScreen ? "column" : "row",
        gap: 2,
        p: 2,
        justifyContent: "space-between",
        borderRadius: "8px",
        backgroundColor: "#f9f9f9",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          gap: 2,
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        {[
          {
            label: "From Date",
            type: "date",
            value: filter?.fromDate,
            key: "fromDate",
          },
          {
            label: "To Date",
            type: "date",
            value: filter?.toDate,
            key: "toDate",
          },
        ].map(({ label, type, value, key }) => (
          <Box key={key} sx={{ width: isSmallScreen ? "100%" : "180px" }}>
            <Typography variant="caption">{label}</Typography>
            <TextField
              sx={{ height: "38px" }}
              type={type}
              value={value}
              onChange={(e) => setFilter({ ...filter, [key]: e.target.value })}
              fullWidth
            />
          </Box>
        ))}

        <Box sx={{ width: isSmallScreen ? "100%" : "180px" }}>
          <Typography variant="caption">Order Status</Typography>
          <Select
            fullWidth
            value={filter?.orderStatus}
            onChange={(e) =>
              setFilter({ ...filter, orderStatus: e.target.value as any })
            }
            sx={{ height: "38px" }}
          >
            {[
              "All",
              OrderStatus.NotPaid,
              OrderStatus.Paid,
              OrderStatus.Delivered,
              OrderStatus.Cancelled,
              OrderStatus.Processing,
            ].map((status) => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Box sx={{ width: isSmallScreen ? "100%" : "180px" }}>
          <Typography variant="caption">Payment Status</Typography>
          <Select
            fullWidth
            sx={{ height: "38px" }}
            value={filter?.paymentStatus}
            onChange={(e) =>
              setFilter({ ...filter, paymentStatus: e.target.value as any })
            }
          >
            {["All", PaymentStatus.Success, PaymentStatus.Failed, "Other"].map(
              (status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              )
            )}
          </Select>
        </Box>

        {adminAccess && (
          <Box sx={{ width: isSmallScreen ? "100%" : "180px" }}>
            <Typography variant="caption">By Staff</Typography>
            <Select
              fullWidth
              sx={{ height: "38px" }}
              value={filter?.staffId}
              onChange={(e) =>
                setFilter({ ...filter, staffId: e.target.value as any })
              }
            >
              <MenuItem value={"All"}>All</MenuItem>
              {staffs.map((s) => (
                <MenuItem key={s.badgeNumber} value={s.badgeNumber}>
                  {`${s.firstname} | ${s.badgeNumber}`}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          gap: 1,
          mt: isSmallScreen ? 2 : 0,
        }}
      >
        <Btn
          onClick={resetFilter}
          text={"Reset"}
          style={{ height: "35px", width: "fitContent" }}
        />

        <Btn
          onClick={() => navigate("/order/new")}
          text={"New Order"}
          style={{ height: "35px", width: "88px" }}
        />
      </Box>
    </Box>
  );
};

export default OrderFilter;
