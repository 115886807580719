const pickup = "/pickup";

type ApiPath = {
  frontend: string;
  backend: string;
};

export const PickupPath = {
  pickup,
};

const api_prefix = "api";

const handlePath = ({ path }: { path: string }) => {
  const apiPath: ApiPath = {
    frontend: `${api_prefix}${pickup}/${path}`,
    backend: `/${path}`,
  };
  return apiPath;
};

export const Pickup_API_PATH = {
  findOrderByPickupCode: (pickupCode: string) =>
    handlePath({ path: "code/" + pickupCode }),
  findOrderByQRCode: (qrCodeValue: string) =>
    handlePath({ path: "qrCode/" + qrCodeValue }),
  requestPickupVerification: handlePath({ path: "verify" }),
};
