

const shift = "/shift";

type ApiPath = {
  frontend: string;
  backend: string;
};

export const ShiftPath = {
  shift ,
};

const api_prefix = "api";

const handlePath = ({ path }: { path: string }) => {
  const apiPath: ApiPath = {
    frontend: `${api_prefix}${shift}/${path}`,
    backend: `/${path}`,
  };
  return apiPath;
};

export const Shift_API_PATH = {
  startShift: handlePath({ path: "start" }),
  endShift: handlePath({ path: "end" }),
  getActiveShift: handlePath({ path: "active" }),
  getTodayShit: handlePath({ path: "today" }),
  getAllTodayShit: handlePath({ path: "all_today" }),
  getShift: (id: string) =>  handlePath({ path: id }),
  getActiveShifts: handlePath({ path: "all_active" }),
  getShiftByStaff: (staffId: string) =>  handlePath({ path: "active/" + staffId }),	
};
