import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import {
	getActiveShiftApi,
	getShiftApi,
	StaffAccess,
	staffHasAnyAccess,
	staffHasOnlyStaffAccess,
} from "shared";
import { getAppData } from "../api/app";
import {
	useAuthStore,
	useClientStore,
	useAppDataStore,
	useScreenTypeStore,
} from "store";
import { cookieAuthApi } from "api/auth";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "ui/isLoading";
import { useShiftStore } from "store/shift";
import { AxiosApi } from "api";
import { da } from "date-fns/locale";

const ProtectedRoute: React.FC<{
	children: React.ReactNode;
	requiredAccess: StaffAccess[];
}> = ({ children, requiredAccess }) => {
	const [loading, setLoading] = useState(true);
	const { staff } = useClientStore();
	const { updateScreenType } = useScreenTypeStore();
	const { appData, loadedAt, setData } = useAppDataStore();
	const { setAuth, setAuthChecked, ...auth } = useAuthStore();
	const { setClient } = useClientStore();
	const { shift, setShift } = useShiftStore();
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		updateScreenType(); // Check on mount
		window.addEventListener("resize", updateScreenType);

		handleApis()
			.then(() => {})
			.catch(() => {})
			.finally(() => {
				setLoading(false);
			});

		handleAuth()
			.then(() => {})
			.catch(() => {})
			.finally(() => {});

		return () => window.removeEventListener("resize", updateScreenType);
	}, []);

	const handleApis = async () => {
		try {
			if (!shift || !shift?.workingNow) {
				const _shift = await getActiveShiftApi(AxiosApi);
				if (_shift) {
					setShift(_shift);
				}
			}

			// if (!appData) {
			// 	const data = await getAppData();
			// 	setData(data);
			// }
		} catch (error) {}
	};
	const handleAuth = async () => {
		cookieAuthApi()
			.then((response) => {
				setAuth(response.auth);
				if (response.client.staff) {
					response.client.staff.access = [StaffAccess.Staff];
				}
				setClient(response.client);
			})
			.catch((err) => {
				setAuthChecked();
				navigate("/", {
					state: {
						from: location.pathname || "/",
					},
				});
			});
	};

	if (loading || !auth.isLogin) return <Loading />;

	if (!auth.isLogin) {
		return <Navigate to="/login" replace />;
	}

	if (!staff) {
		return <Navigate to="/access-denied" replace />;
	}

	if (!staff?.isCurrentStaff) {
		return <Navigate to="dashboard/access-denied" replace />;
	}

	if (
		!staffHasAnyAccess({
			currentAccess: staff.access,
			expectedAccess: requiredAccess,
		})
	) {
		return <Navigate to="/dashboard/access-denied" replace />;
	}

	if (
		location.pathname !== "/dashboard/shift"
		//&&
		// staffHasOnlyStaffAccess(staff.access)
	) {
		if (!shift || !shift.workingNow) {
			return <Navigate to="shift" replace />;
		}
	}

	return <>{children}</>;
};

export default ProtectedRoute;
