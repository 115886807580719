import React, { FC } from "react";
import { CheckCircle, Divide } from "lucide-react";
import { Card, CardContent, CardHeader, Divider } from "@mui/material";
import { Order } from "shared";
import { Btn } from "ui/button";
import CustomItemFlex from "ui/Items";
import { handleTimePassed } from "utils/dates";
import { BodyHeader } from "ui/typography";

interface Action {
	title: string;
	onClick: () => void;
}

const OrderAlreadyPicked: FC<Order & { action: Action; title?: string }> = ({
	action,
	title = "Order Already Picked",
	...order
}) => {
	return (
		<div>
			<Card
				sx={{
					width: {
						xs: "100%", // 100% width on extra-small screens
						sm: "80%", // 80% width on small screens
						md: "60%", // 60% width on medium screens
						lg: "50%", // 50% width on large screens
						xl: "40%", // 40% width on extra-large screens
					},
					margin: "auto",
				}}
			>
				<CardContent>
					<div>
						<div>
							<CheckCircle color="green" size={50} />
						</div>
						<div style={{ marginTop: "30px" }}>
							<BodyHeader text={title} />
						</div>

						<p>This order has already been picked. Here are the details:</p>
						<div style={{ marginTop: "30px" }}>
							<CustomItemFlex
								text={"Order Number"}
								value={`# ${order.orderNumber}`}
							/>
							<CustomItemFlex
								text={"Client Name"}
								value={`${order.firstname}  ${order.lastname}`}
							/>
							<CustomItemFlex
								text={"Pickup Time"}
								value={` ${handleTimePassed(order.deliveredDate as any)}`}
							/>
							<CustomItemFlex
								text={"Quantity"}
								value={` ${order.quantityKg} Kg`}
							/>

							<Divider style={{ marginTop: "20px", marginBottom: "15px" }} />
							<CustomItemFlex
								text={"Process by"}
								value={` ${order.processedByStaff?.firstname}`}
							/>
						</div>
					</div>
				</CardContent>
				<div style={{ marginBottom: "40px" }}>
					<Btn
						text={action.title}
						onClick={action.onClick}
						style={{ width: "100px" }}
					/>
				</div>
			</Card>
		</div>
	);
};

export default OrderAlreadyPicked;
