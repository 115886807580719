import { io, Socket } from "socket.io-client";
export * from "./constants";
const socketUri =
  process.env.NODE_ENV === "production"
    ? "https://urbanlinegas.com"
    : "http://localhost:8080";

let ClientSocket: Socket;
const eventListeners: Map<string, (...args: any[]) => void> = new Map();

const getPlatform = (): "web" | "mobile" => {
  return /Mobi|Android/i.test(navigator.userAgent) ? "mobile" : "web";
};

export const startClientSocket = (token?: string) => {
  console.log("starting socket");
  if (!ClientSocket || !ClientSocket.connected) {
    ClientSocket = io(socketUri, {
      withCredentials: true,
      auth: {
        token,
        platform: getPlatform(),
      },
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 2000,
    });

    ClientSocket.on("connect", () => {
      console.log("✅ Connected to server");
      reattachEventListeners();
    });

    ClientSocket.on("disconnect", (reason) => {
      console.warn(`⚠️ Disconnected: ${reason}`);
    });

    ClientSocket.on("reconnect_attempt", (attempt) => {
      console.info(`🔄 Reconnect attempt ${attempt}`);
    });

    ClientSocket.on("reconnect", (attempt) => {
      console.log(`✅ Reconnected after ${attempt} attempts`);
      reattachEventListeners(); // 🔄 Ensure events are reattached
    });

    ClientSocket.on("reconnect_failed", () => {
      console.error("❌ Reconnection failed. Please check your connection.");
    });
  }

  return ClientSocket;
};

const reattachEventListeners = () => {
  eventListeners.forEach((callback, event) => {
    ClientSocket.on(event, callback);
  });
};

export const onClientSocketEvent = (
  event: string,
  callback: (...args: any[]) => void
) => {
  if (ClientSocket) {
    ClientSocket.on(event, callback);
    eventListeners.set(event, callback); // 🗃️ Store for reconnection
  }
};

export const emitClientSocketEvent = (event: string, ...args: any[]) => {
  if (ClientSocket && ClientSocket.connected) {
    ClientSocket.emit(event, ...args);
  }
};

export const offClientSocketEvent = (
  event: string,
  callback?: (...args: any[]) => void
) => {
  if (ClientSocket) {
    if (callback) {
      ClientSocket.off(event, callback);
      eventListeners.delete(event);
    } else {
      ClientSocket.removeAllListeners(event);
      eventListeners.delete(event);
    }
  }
};

export const reconnectClientSocket = (token?: string) => {
  if (ClientSocket && !ClientSocket.connected) {
    console.warn("🔄 Attempting manual reconnection...");
    ClientSocket.connect();
  } else if (!ClientSocket) {
    console.warn("🔄 No active socket. Re-initializing...");
    startClientSocket(token);
  } else {
    console.log("✅ Socket is already connected.");
  }
};

export const disconnectClientSocket = () => {
  if (ClientSocket && ClientSocket.connected) {
    ClientSocket.disconnect();
    eventListeners.clear();
  }
};
