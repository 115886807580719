import { Typography } from "@mui/material";
import { ScreenType } from "interface";
import useScreenTypeStore from "store/screenType";

export const Body: React.FC<{
	text: string;
	color?: string;
	style?: React.CSSProperties;
}> = ({ text, style }) => {
	return (
		<p className="text-body" style={style}>
			{text}
		</p>
	);
};

export const BodyWithClass: React.FC<{
	text: string;
	color?: string;
	className: string;
	style?: React.CSSProperties;
}> = ({ text, style, className }) => {
	return (
		<p
			className={className}
			style={{ marginTop: "1px", marginBottom: "1px", ...style }}
		>
			{text}
		</p>
	);
};

export const BodyHeader: React.FC<{ text: string; color?: string }> = ({
	text,
	color = "black",
}) => {
	return (
		<Typography color={color} variant="h4" component="h3">
			{text}
		</Typography>
	);
};

export const BodyStrong: React.FC<{ text: string; color?: string }> = ({
	text,
	color = "black",
}) => {
	return (
		<Typography color={color} variant="h5" component="h5">
			{text}
		</Typography>
	);
};

export const Header: React.FC<{
	text: string;
	color?: string;
	style?: React.CSSProperties;
}> = ({ text, color, style }) => {
	const { screenType } = useScreenTypeStore();

	return (
		<h1
			className={
				screenType === ScreenType.Desktop ? "text-header" : "text-header-mobile"
			}
			style={{ color, textAlign: "center", ...style }}
		>
			{text}
		</h1>
	);
};

export const ErrorMessage: React.FC<{ text?: string }> = ({ text }) => {
	if (!text) return null;
	return <span style={{ color: "red" }}>{text}</span>;
};
