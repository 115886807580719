import { Avatar, Box, Card, CardContent, Typography } from "@mui/material";
import { format } from "path";
import React, { FC } from "react";
import {
  PaymentPurpose,
  WalletTransaction,
  WalletTransactionType,
} from "shared";
import { Body, BodyWithClass } from "ui/typography";
import { NairaSign } from "utils/constants";
import { formatNigeriaNumber, handleDate } from "utils/helper";

const WalletHistoryCard: FC<WalletTransaction & { key: any }> = ({ ...tr }) => {
  const { amount, purpose, status, type } = tr;

  const handleAvatarText = (purpose: PaymentPurpose) => {
    if (purpose === PaymentPurpose.FundVirtualAccount) {
      return tr.from.bankName;
    }
  };
  const handleAmount = (amount: number) => {
    const sign = type === WalletTransactionType.Credit ? "-" : "+";
    return ` ${sign} ${NairaSign} ${formatNigeriaNumber(amount)}`;
  };

  return (
    <Card key={tr.key} sx={{ display: "flex" }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Avatar>{tr.from.bankName}</Avatar>
          <Box>
            <BodyWithClass text={tr.displayName} className={"poppins-bold"} />
            <BodyWithClass
              text={handleDate(tr.createdAt)}
              className={"poppins-bold"}
            />
          </Box>
          <Box>
            <BodyWithClass text={"Amount"} className={"poppins-bold"} />
            <BodyWithClass
              text={handleAmount(amount)}
              className={"poppins-bold"}
            />
          </Box>
        </CardContent>
      </Box>
      {/* <CardMedia
				component="img"
				sx={{ width: 151 }}
				image="/image/gas_cylinder.png"
				alt="order"
			/> */}
    </Card>
  );
};

export default WalletHistoryCard;
