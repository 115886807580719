import { AxiosInstance, isAxiosError } from "axios";
import { Staff, StaffForm } from "../interface";
import { API_PATH } from "../apiPath";

export const createStaffApi = async (
  axiosInstance: AxiosInstance,
  form: StaffForm
): Promise<any> => {
  try {
    const data = await axiosInstance.post<any>(API_PATH.staff.create.frontend, form);
    return data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
};

export const getActiveStaffApi = async (
  axiosInstance: AxiosInstance
): Promise<Staff[]> => {
  try {
    const data = await axiosInstance.get<Staff[]>(
      API_PATH.staff.getActiveStaffs.frontend
    );
    return data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
};
