import { isAxiosError, AxiosInstance } from "axios";
import {
  Auth,
  DataInterface,
  LoginForm,
  LoginResponse,
  OptResponseDta,
  OTPConfirmation,
} from "../interface";
import { API_PATH } from "../apiPath";

export const signupApi = async (
  axiosInstance: AxiosInstance,
  { mobileNumber }: { mobileNumber: string }
): Promise<Auth> => {
  try {
    const _data = await axiosInstance.post<Auth>(API_PATH.auth.signUp.frontend, {
      mobileNumber,
    });
    return _data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
 };

export const loginApi = async (
  axiosInstance: AxiosInstance,
  form: LoginForm
): Promise<Auth> => {
  try {
    const _data = await axiosInstance.post<Auth>(API_PATH.auth.login.frontend, form);
    return _data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
 };

export const cookieAuthApi = async (
  axiosInstance: AxiosInstance
): Promise<DataInterface> => {
  try {
    const _data = await axiosInstance.get<DataInterface>(
      API_PATH.auth.authByCookie.frontend
    );
    return _data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
 };

export const optVerificationApi = async (
  axiosInstance: AxiosInstance,
  form: OTPConfirmation
): Promise<LoginResponse> => {
  try {
    const _data = await axiosInstance.post<LoginResponse>(
      API_PATH.auth.optConfirmation.frontend,
      form
    );
    return _data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
 };

export const optRequestApi = async (
  axiosInstance: AxiosInstance,
  { mobileNumber }: { mobileNumber?: string }
) => {
  try {
    const _data = await axiosInstance.post<OptResponseDta>(
      API_PATH.auth.optRequest.frontend,
      {
        mobileNumber,
      }
    );
    return _data.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
 };
