import React from "react";
import LandingPage from "../ui/landingPage/LandingPages";
import { useAuthStore, useClientStore } from "store";
import { LoginHome } from "ui/landingPage/Home";
import Loading from "ui/isLoading";
import { COLORS } from "shared";

const Home = () => {
	const { client } = useClientStore();
	const { authChecked } = useAuthStore();
	if (!authChecked) return <Loading />;
	return (
		<div style={{ backgroundColor: COLORS.background.highlight }}>
			{client ? <LoginHome /> : <LandingPage />}
		</div>
	);
};

export default Home;
