import { AxiosInstance } from "axios";
import { isAxiosError } from "axios";
import { GasDeliveryForm } from "../interface";

export const newDeliveryApi = async (axiosInstance: AxiosInstance, form: GasDeliveryForm): Promise<any> => {
  try {
    const data = await axiosInstance.post<any>(`/supply/delivery`, form);
    return data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error.response?.data;
    }
    throw error;
  }
};
