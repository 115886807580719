import React, { FC, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Box,
  Stack,
  Switch,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Wallet } from "shared";

import { BodyWithClass } from "ui/typography";
import { useClientStore } from "store";
import { NairaSign } from "utils/constants";
import { formatNigeriaNumber } from "utils/helper";

export const WalletCard: React.FC<
  Wallet & { showDetails: boolean; setShowDetail: () => void }
> = ({ showDetails = false, setShowDetail, ...wallet }) => {
  const [showSensitiveData, setShowSensitiveData] = useState(false);
  const [showBankDetail, setShowBankDetail] = useState(false);

  const { client } = useClientStore();

  const maskCardNumber = (cardNumber: string): string => {
    const last4 = cardNumber.slice(-4);
    return `•••• •••• •••• ${last4}`;
  };

  const maskPin = (pin: string): string => {
    return "•".repeat(pin.length);
  };
  return (
    <Card
      elevation={3}
      sx={{
        background: "linear-gradient(135deg, #1a237e 0%, #0d47a1 100%)",
        color: "white",
        position: "relative",
        // minHeight: "200px",
        overflow: "visible",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          background:
            "linear-gradient(45deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 100%)",
          borderRadius: "inherit",
        },
      }}
    >
      <CardContent>
        <div style={{ textAlign: "left" }}>
          {showDetails ? (
            <>
              <Stack
                direction="row"
                spacing={1}
                sx={{ alignItems: "center", justifyContent: "center" }}
              >
                <BodyWithClass
                  text={"Wallet"}
                  className={"poppins-light-italic"}
                  style={{ fontSize: "12px" }}
                />
                <Switch
                  className="card-witch"
                  checked={showBankDetail}
                  onChange={(e) => setShowBankDetail(e.target.checked)}
                />
                <BodyWithClass
                  text={"Bank"}
                  className={"poppins-light-italic"}
                  style={{ fontSize: "12px" }}
                />
              </Stack>
              {showBankDetail ? (
                <>
                  <div style={{}}>
                    <BodyWithClass
                      text={"Bank Name"}
                      className={"poppins-light-italic"}
                      style={{ fontSize: "12px" }}
                    />
                    <BodyWithClass
                      text={wallet.virtualAccount?.bankName as any}
                      className={"poppins-medium"}
                      style={{ marginLeft: "8px" }}
                    />
                  </div>
                  <div style={{}}>
                    <BodyWithClass
                      text={"Bank Account number"}
                      className={"poppins-light-italic"}
                      style={{ fontSize: "12px" }}
                    />
                    <BodyWithClass
                      text={wallet.virtualAccount?.accountNumber as any}
                      className={"poppins-medium"}
                      style={{ marginLeft: "8px" }}
                    />
                  </div>

                  <div style={{}}>
                    <BodyWithClass
                      text={"Account Name"}
                      className={"poppins-light-italic"}
                      style={{ fontSize: "12px" }}
                    />
                    <BodyWithClass
                      text={wallet.virtualAccount?.accountName as any}
                      className={"poppins-medium"}
                      style={{ marginLeft: "8px" }}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div style={{}}>
                    <BodyWithClass
                      text={"Wallet Number"}
                      className={"poppins-light-italic"}
                      style={{ fontSize: "12px" }}
                    />
                    <BodyWithClass
                      text={wallet.walletNumber as any}
                      className={"poppins-medium"}
                      style={{ marginLeft: "8px" }}
                    />
                  </div>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="body1"
                      sx={{ mr: 1, fontFamily: '"Roboto Mono", monospace' }}
                    >
                      PIN: {}
                    </Typography>
                    <IconButton
                      size="small"
                      onClick={() => setShowSensitiveData(!showSensitiveData)}
                      sx={{ color: "white" }}
                    >
                      {showSensitiveData ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </Box>
                </>
              )}
            </>
          ) : (
            <>
              <BodyWithClass
                text={"UG Wallet"}
                className={"poppins-medium"}
                style={{ textAlign: "right" }}
              />
              <div>
                <BodyWithClass
                  text={"Balance"}
                  className={"poppins-light-italic"}
                  style={{ fontSize: "12px" }}
                />
                <BodyWithClass
                  text={
                    (NairaSign +
                      " " +
                      formatNigeriaNumber(wallet.balance)) as any
                  }
                  className={"poppins-medium"}
                  style={{ marginLeft: "8px" }}
                />
              </div>
            </>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <BodyWithClass
              text={client?.firstname + " " + client?.lastname}
              className={"poppins-light-italic"}
            />
            <IconButton
              size="small"
              onClick={() => setShowDetail()}
              sx={{ color: "white" }}
            >
              {showSensitiveData ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

const Item: FC<{ text: string; value: any }> = ({ text, value }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <BodyWithClass text={text} className={"poppins-medium"} />
      <BodyWithClass text={text} className={"poppins-medium"} />
    </Box>
  );
};
