export enum DeliveryMethods {
	Car = "Car",
	Bike = "Bike",
	Scooter = "Scooter",
	Truck = "Truck",
	Walking = "Walking", // For small deliveries
}

export enum UserRole {
  Passenger = "Passenger",
  Carrier = "Carrier",
}

export interface Location {
  lat: number;
  long: number;
  address: string;
  description: string;
  homeImage?: string;
}
export enum RideStatus {
    Pending = "Pending",
    Accepted = "Accepted",
    InProgress = "InProgress",
    Rejected = "Rejected",
    Completed = "Completed",
    Cancelled = "Cancelled",
}