import QrScanner from "qr-scanner";
import { FC, useEffect, useRef } from "react";

let count = 0;
const QRScanner: FC<{ setReadData: (data: string) => void }> = ({
  setReadData,
}) => {
  const videoElementRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (count > 0) return;
    if (!videoElementRef.current) return;
    const qrScanner = new QrScanner(
      videoElementRef.current,

      (result) => {
        console.log("decoded qr code:", result);
        setReadData(result.data);
      },
      {
        returnDetailedScanResult: true,
        highlightScanRegion: true,
        highlightCodeOutline: true,
        onDecodeError: (err) => {
          console.log("Error ,", err);
        },
      }
    );

    const startScanner = async () => {
      try {
        console.log(qrScanner as any);
        const tes = await qrScanner.start();
        console.log(tes);
      } catch (err) {
        console.error("Failed to start scanner:", err);
        alert("Error starting scanner: " + err);
      }
    };
    // startScanner();
    count += 1;

    function onScanSuccess(decodedText: any, decodedResult: any) {
      // handle the scanned code as you like, for example:
      console.log(`Code matched = ${decodedText}`, decodedResult);
    }

    function onScanFailure(error: any) {
      // handle scan failure, usually better to ignore and keep scanning.
      // for example:
      console.warn(`Code scan error = ${error}`);
    }

    // let html5QrcodeScanner = new Html5QrcodeScanner(
    //   "reader",
    //   { fps: 10, qrbox: { width: 250, height: 250 } },
    //   /* verbose= */ false
    // );
    // html5QrcodeScanner.render(onScanSuccess, onScanFailure);
    return () => {
      qrScanner.stop();
      qrScanner.destroy();
      //   count = 0;
    };
  }, []);

  return (
    <div className="videoWrapper" style={{ maxWidth: "100%", padding: "20px" }}>
      <div id="reader"></div>
      <video
        className="qrVideo"
        ref={videoElementRef}
        // onPlay={() => videoElementRef.current?.load()}
      />
    </div>
  );
};

export default QRScanner;
