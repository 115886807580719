import React, { FC, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
// import { MaterialIcons } from "@mui/icons-material";
import { getOrderByNumberApi, verifyOrderPaymentApi } from "api/order";
import {
  Order,
  Payment,
  PaymentStatus,
  PaymentUsableData,
  PaymentVerification,
} from "shared";
import { Btn } from "ui/button";
import Loading from "ui/isLoading";
import Info from "ui/Info";
import { PaymentPurpose } from "shared";
import {
  discardPaymentUsableRedisDataApi,
  getByPaymentNumberApi,
  getPaymentUsableDataApi,
  savePaymentUsableDataApi,
  verifyPaymentApi,
} from "api/paymnet";
import { COLORS, ICON_NAMES } from "utils/constants";
import { BodyWithClass } from "ui/typography";

import BankCardDetails from "ui/payment/BankCard";

const PaymentSuccess: FC = () => {
  const { id, paymentNumber, purpose } = useParams();
  const [order, setOrder] = useState<Order | null>(null);
  const [payment, setPayment] = useState<Payment | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [fetchCount, setFetchCount] = useState(0);
  const [verification, setVerification] = useState<PaymentVerification | null>(
    null
  );

  const [bankCardData, setBankCardData] = useState<PaymentUsableData | null>(
    null
  );

  const [loadingCard, setLoadingCard] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        fetchCount < 4 &&
        (verification === null || verification.status === PaymentStatus.Pending)
      ) {
        handleFetchData();
      } else {
        clearInterval(interval);
        setLoading(false);

        if (
          !verification?.paymentSuccess ||
          verification.status !== PaymentStatus.Success
        ) {
          setError(true);
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [fetchCount, verification]);

  const handleSaveBankCard = () => {
    savePaymentUsableDataApi()
      .then((response) => {})
      .catch((err) => {})
      .finally(() => {
        handleNavigate();
      });
  };

  const handleDiscardBankCard = () => {
    discardPaymentUsableRedisDataApi()
      .then((response) => {})
      .catch((err) => {})
      .finally(() => {
        handleNavigate();
      });
  };

  const handleNavigate = () => {
    if (purpose === PaymentPurpose.FundWallet) {
      navigate("/wallet");
    } else if (purpose === PaymentPurpose.OrderPayment) {
      navigate(`/order/${id}`);
    } else {
      navigate("/");
    }
  };

  const handleFetchData = async () => {
    try {
      verifyPaymentApi(paymentNumber as any).then((response) => {
        setVerification(response);
      });
    } catch (err) {
      setError(true);
    }

    getPaymentUsableDataApi()
      .then((data) => {
        console.log(data);
        setBankCardData(data);
      })
      .catch((err) => {
        console.log(console.log(err));
      })
      .finally(() => {
        setLoadingCard(false);
      });
    setFetchCount((prevCount) => prevCount + 1);
  };

  if (loading) return <Loading />;

  return (
    <div
      style={{
        height: "90vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {error ? (
        <Info
          title="Payment Error"
          description="Something went wrong with the payment."
          subDescription={`The payment ${
            purpose === PaymentPurpose.OrderPayment
              ? `For Order number # ${id} `
              : ""
          } will be process soon If not, contact support.`}
          show={error}
          setShow={() => setError(false)}
          actions={[
            {
              onClick: handleNavigate,
              text: "Continue",
            },
          ]}
        />
      ) : (
        <div
          style={{
            height: 600,
            maxHeight: "100%",
            margin: "auto",
          }}
        >
          <div>
            <img src={`/icon/${ICON_NAMES.SuccessBlue}.svg`} alt="success" />
            <div>
              <BodyWithClass
                text={"Payment successful!"}
                className={"poppins-semibold"}
                style={{ fontSize: "22px", color: COLORS.Blue }}
              />
            </div>
          </div>

          <div style={{ margin: "30px" }}>
            {purpose === PaymentPurpose.OrderPayment ? (
              <div style={{ display: "flex", gap: 5 }}>
                <BodyWithClass
                  text={`Your payment for order number`}
                  className={"poppins-medium "}
                  style={{ fontSize: "14px", color: COLORS.Grey100 }}
                />
                <BodyWithClass
                  text={` #${id}`}
                  className={"poppins-semibold-italic "}
                  style={{ fontSize: "14px", color: COLORS.Blue }}
                />
                <BodyWithClass
                  text={` is completed`}
                  className={"poppins-medium "}
                  style={{ fontSize: "14px", color: COLORS.Grey100 }}
                />
              </div>
            ) : (
              <BodyWithClass
                text={"Your wallet has been funded successfully"}
                className={"poppins-medium "}
                style={{ fontSize: "14px", color: COLORS.Grey100 }}
              />
            )}
          </div>
          <div>
            {loadingCard ? (
              <Loading />
            ) : (
              <>
                {bankCardData ? (
                  <BankCardDetails
                    {...bankCardData}
                    saveData={handleSaveBankCard}
                    discardData={handleDiscardBankCard}
                  />
                ) : (
                  <div>
                    <Btn
                      text={"Continue "}
                      onClick={() => navigate(`/order/${order?.orderNumber}`)}
                    />
                  </div>
                )}
              </>
            )}
          </div>
          {/* {order ? (
						<div>
							{purpose === PaymentPurpose.FundVirtualAccount ? (
								<BodyWithClass
									text={
										"Payment success, you will be redirect to the order page in "
									}
									className={"poppins-semibold"}
									style={{ fontSize: "22px", color: COLORS.Blue }}
								/>
							) : (
								<BodyWithClass
									text={
										"Payment success, you will be redirect to the order page in 1  minute  or click blow to go to order page"
									}
									className={"poppins-semibold"}
									style={{ fontSize: "22px", color: COLORS.Blue }}
								/>
							)}
						</div>
					) : (
						<Loading />
					)} */}
        </div>
      )}
    </div>
  );
};

export default PaymentSuccess;
