import { format } from "date-fns";

export const isValidPhoneNumber = (input: string): boolean => {
	const localPhoneRegex = /^0[7-9][0-1][0-9]{8}$/;
	return localPhoneRegex.test(input.trim());
};

export function formatNigeriaNumber(
	num: number,
	minDecimal: number = 2,
	maxDecimal: number = 2
) {
	if (num === null || num === undefined) {
		return "";
	}
	const formatter = new Intl.NumberFormat("en-US", {
		minimumFractionDigits: minDecimal,
		maximumFractionDigits: maxDecimal,
	});
	return formatter.format(num);
}

export const handleDate = (date: Date | string) => {
	if (date === null || date === undefined) {
		return "";
	}
	return format(new Date(date), "dd/MM/yyyy");
	// return new Date(date).toLocaleDateString();
};

export const handlePhoneNumberInput = (input: string) => {
	const phoneRegex = /^(0\d{10}|234\d{10})$/;
	let phone = "";
	let phone234 = "";
	let passCheck = false;

	if (phoneRegex.test(input)) {
		passCheck = true;
		if (input.startsWith("0")) {
			phone = input;
			phone234 = "234" + input.slice(1);
		} else if (input.startsWith("234")) {
			phone234 = input;
			phone = "0" + input.slice(3);
		}
	}

	return {
		phone234,
		phone,
		passCheck,
	};
};
