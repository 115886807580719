import { ADMIN_API_PATH, AdminPath } from "./admin";
import { AUTH_API_PATH, CLIENT_API_PATH, ClientPath } from "./client";
import { Order_API_PATH, OrderPath } from "./order";
import { Payment_API_PATH, PaymentPath } from "./payment";
import { Pickup_API_PATH, PickupPath } from "./pickup";
import { Product_API_PATH, ProductPath } from "./product";
import { Quota_API_PATH, QuotaPath } from "./quota";
import { Sales_API_PATH, SalesPath } from "./sales";
import { Shift_API_PATH, ShiftPath } from "./shift";
import { Staff_API_PATH, StaffPath } from "./staff";
import { Supplier_API_PATH, SupplierPath } from "./supplier";
import { Supply_API_PATH, SupplyPath } from "./supply";

import { FILE_UPLOAD_API_PATH, FileUploadPath } from "./upload";
import { Wallet_API_PATH, WalletPath } from "./wallet";

const port = 8080;
let BASE_URI: string;
const hostname = window.location.hostname;

if (hostname.includes("urbanlinegas")) {
  BASE_URI = "https://api.urbanlinegas.com";
} else {
  BASE_URI = `http://localhost:${port}`;
}
const hubixlabUtils = "/hubixlab_utils";
const payment = "/payment";
const app = "/app";

export const api_prefix = "api";

type ApiPath = {
  frontend: string;
  backend: string;
};

export const PATHS = {
  ...ClientPath,
  ...OrderPath,
  ...AdminPath,
  ...WalletPath,
  ...PaymentPath,
  ...SupplyPath,
  ...SupplierPath,
  ...QuotaPath,
  ...ProductPath,
  ...StaffPath,
  hubixlab_utils: hubixlabUtils,
  ...FileUploadPath,
  ...SalesPath,
  payment,
  ...PickupPath,
  ...ShiftPath,
  app,
};

const handlePath = ({ BasePath, path }: { BasePath: string; path: string }) => {
  const apiPath: ApiPath = {
    frontend: `${api_prefix}${BasePath}/${path}`,
    backend: `/${path}`,
  };
  return apiPath;
};

const appApi = {
  getAppData: handlePath({
    BasePath: PATHS.app,
    path: "data",
  }),
};

export const API_PATH = {
  port,
  base_uri: `${BASE_URI}/`,
  order: Order_API_PATH,
  admin: ADMIN_API_PATH,
  client: CLIENT_API_PATH,
  auth: AUTH_API_PATH,
  wallet: Wallet_API_PATH,
  payment: Payment_API_PATH,
  supply: Supply_API_PATH,
  supplier: Supplier_API_PATH,
  fileUpload: FILE_UPLOAD_API_PATH.uploadFile,
  quota: Quota_API_PATH,
  product: Product_API_PATH,
  staff: Staff_API_PATH,
  app: appApi,
  sales: Sales_API_PATH,
  pickup: Pickup_API_PATH,
  shift: Shift_API_PATH,
  addEmployee: handlePath({
    BasePath: PATHS.app,
    path: "add_employee",
  }),
};
