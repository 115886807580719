import { Card, CardContent, Typography } from "@mui/material";
import React, { FC } from "react";
import { PaymentUsableData } from "shared";
import { Btn } from "ui/button";
import { BodyWithClass } from "ui/typography";

const BankCardDetails: FC<
  PaymentUsableData & { saveData: () => void; discardData: () => void }
> = ({ ...paymentCard }) => {
  return (
    // <Card>
    //   <CardContent>
    //     <BodyWithClass text={"Payment Details"} className={""} />
    //     <BodyWithClass text={"Last 4 Digits"} className={""} />

    //     <Typography variant="body2" component="p">
    //       <strong>Last 4 Digits:</strong> {paymentCard.last4}
    //     </Typography>
    //     <Typography variant="body2" component="p">
    //       <strong>BIN:</strong> {paymentCard.bin}
    //     </Typography>
    //     <Typography variant="body2" component="p">
    //       <strong>Expiration Date:</strong> {`${paymentCard.expireMonth}`}
    //     </Typography>
    //     <div>
    //       <Btn onClick={paymentCard.saveData} text="Save" />
    //       <Btn onClick={paymentCard.discardData} text="Don't Save" />
    //     </div>
    //   </CardContent>
    // </Card>
    <div
      style={{
        padding: "16px",
        border: "1px solid #ddd",
        borderRadius: "8px",
        backgroundColor: "#f9f9f9",
        maxWidth: "400px",
        margin: "16px auto",
      }}
    >
      <div style={{ marginBottom: "16px" }}>
        <h3 style={{ margin: "0 0 8px", fontSize: "1.2rem", color: "#333" }}>
          Card Details
        </h3>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "8px",
          }}
        >
          <span style={{ fontWeight: "bold", color: "#555" }}>
            Card Number:
          </span>
          <span>{`${paymentCard.bin}xxxx${paymentCard.last4}`}</span>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span style={{ fontWeight: "bold", color: "#555" }}>
            Expire Date:
          </span>
          <span>{`${paymentCard.expireMonth}/${paymentCard.expireYear}`}</span>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "16px",
        }}
      >
        <button
          onClick={paymentCard.saveData}
          style={{
            flex: 1,
            padding: "10px",
            backgroundColor: "#4CAF50",
            color: "#fff",
            border: "none",
            borderRadius: "4px",
            fontSize: "1rem",
            cursor: "pointer",
          }}
        >
          Save Card
        </button>
        <button
          onClick={paymentCard.discardData}
          style={{
            flex: 1,
            padding: "10px",
            backgroundColor: "#f44336",
            color: "#fff",
            border: "none",
            borderRadius: "4px",
            fontSize: "1rem",
            cursor: "pointer",
          }}
        >
          Discard
        </button>
      </div>
    </div>
  );
};

export default BankCardDetails;
